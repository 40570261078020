import { LogoList } from '@/modules';
import type { DatoCompany, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type CareerCompanyList = {
  logos: DatoCompany[];
  subtitle: string;
  title: string;
};

type Props = { data: CareerCompanyList } & FlexProps;

export const CareerCompanyList = (props: Props): ReactElement => {
  const {
    data: { title, subtitle, logos = [] },
    ...flexProps
  } = props;

  const logoListData = {
    logos,
    moduleFormat: [
      { background: 'light', spacingBottom: '80', spacingTop: '0' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <LogoList as="section" data={logoListData} {...flexProps} />;
};

export const careerCompanyListFragment = `
  fragment careerCompanyListFragment on CareerCompanyListRecord {
    __typename
    logos {
      ...companyFragment
    }
    subtitle
    title
  }
`;
