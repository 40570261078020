import { Flex, Text } from '@ironhack/design-system2/components';

import { Button, ModuleWrapper, StoryList } from '@/components';
import type {
  DatoButtonBlock,
  DatoModuleFormat,
  DatoStory,
} from '@/lib/datocms';
import { sendEvent } from '@/lib/gtm';
import { usePageContext } from '@/hooks';

import type { FlexProps, TextProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type Stories = {
  button?: [DatoButtonBlock];
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  stories: DatoStory[];
  subtitle?: string;
  title?: string;
};

type Props = { data: Stories } & FlexProps;

export const Stories = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: [button] = [],
    headingProps = {},
    moduleFormat: [moduleFormat],
    stories,
    subtitle,
    title,
  } = data;
  const hasTitleOrSubtitle = title || subtitle;
  const {
    pageData: { gaCategory },
  } = usePageContext<Record<string, unknown>, { gaCategory: string }>();

  return (
    <ModuleWrapper
      innerProps={{
        align: 'center',
        direction: 'column',
        textAlign: ['left', null, 'center'],
      }}
      moduleFormat={moduleFormat}
      outerProps={flexProps}
    >
      {hasTitleOrSubtitle && (
        <Flex direction="column" gap={2} mb={5} width="100%">
          {title && (
            <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text
              // Make sure it's 60% width in desktop in spite of outer padding
              mx={[0, null, 'calc(20% - var(--chakra-space-6))']}
              textStyle="l"
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      )}
      <StoryList
        onStoryView={(userName) => {
          sendEvent({
            eventCategory: gaCategory,
            eventAction: 'play video alumni stories',
            eventLabel: userName,
          });
        }}
        stories={stories}
      />
      {button && <Button {...button} mt={4} />}
    </ModuleWrapper>
  );
};

export const storiesFragment = `
  fragment storiesFragment on StoryListRecord {
    __typename
    button {
      ...buttonFragment
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    stories {
      ...storyFragment
    }
    subtitle
    title
  }
`;
