import { Storytelling } from '@/modules';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

export type NotFoundPageHeader = DatoBlock<{
  title: string;
  description: string;
  buttonText: string;
  image: DatoImage;
}>;

type Props = { data: NotFoundPageHeader } & FlexProps;

export const NotFoundPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { title, description, buttonText, image } = data;

  const button: [DatoButtonBlock] = [
    {
      text: buttonText,
      variant: 'primary',
      target: [
        {
          route: {
            path: '/',
          },
        },
      ],
    },
  ];

  const storytellingData: Storytelling = {
    columns: [
      { image },
      {
        title,
        subtitle: description,
        button,
        flexProps: { my: 'auto' },
      },
    ],
    moduleFormat: [
      { background: 'transparent', spacingTop: '40', spacingBottom: '80' },
    ] as [DatoModuleFormat],
  };
  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const notFoundPageHeaderFragment = `
  fragment notFoundPageHeaderFragment on NotFoundPageHeaderRecord {
    __typename
    title
    description
    buttonText
    button {
      ...buttonFragment
    }
    image {
      responsiveImage(imgixParams: {fit: crop, w: 548, h: 310, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
  }
`;
