import {
  Box,
  Button,
  Flex,
  FormFeedback,
  FormField,
  Input,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { useForm } from 'react-hook-form';
import { nopeResolver } from '@hookform/resolvers/nope';
import { ReactElement, useState } from 'react';
import { Image, StructuredTextGraphQlResponse } from 'react-datocms';
import { Nope } from 'nope-validator';

import { ModuleWrapper, StructuredContent } from '@/components';
import type { DatoBlock, DatoFormInput, DatoImage } from '@/lib/datocms';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';

type FormData = {
  email: string;
};

export type BlogHomeHeader = DatoBlock<{
  buttonText: string;
  image: DatoImage;
  input: DatoFormInput;
  legalText: StructuredTextGraphQlResponse;
  pardotUrl: string;
  submitError: string;
  submitSuccess: string;
  subtitle: StructuredTextGraphQlResponse;
  title: string;
}>;

type Props = { data: BlogHomeHeader };

const eventBase = { eventCategory: 'Blog', eventAction: 'subscribe' };

const events = {
  subscribeClick: (): void =>
    sendEvent({ ...eventBase, eventLabel: 'home - click subscribe' }),
  subscribeSuccess: (): void =>
    sendEvent({ ...eventBase, eventLabel: 'home - successfully subscribe' }),
  subscribeFail: (): void =>
    sendEvent({ ...eventBase, eventLabel: 'home - subscribe failed' }),
};

const getSchema = (
  messages: Record<string, { invalid: string; required: string }>
) =>
  Nope.object().shape({
    email: Nope.string()
      .email(messages.email.invalid)
      .required(messages.email.required),
  });

export const BlogHomeHeader = (props: Props): ReactElement => {
  const { data } = props;
  const {
    buttonText,
    image,
    input,
    legalText,
    pardotUrl,
    submitError,
    submitSuccess,
    subtitle,
    title,
  } = data;

  const {
    params: { language },
  } = usePageContext();

  const imageLayout: 'intrinsic' | 'fixed' = useBreakpointValue({
    base: 'intrinsic',
    lg: 'fixed',
  });

  const formSchema = getSchema({
    email: { invalid: input.invalidMessage, required: input.requiredMessage },
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: nopeResolver(formSchema),
  });
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error' | 'success';
    message: string;
  }>();

  const onSubmit = async (formData: FormData): Promise<void> => {
    events.subscribeClick();
    setFormFeedback(null);
    try {
      const response = await fetch('/api/pardot/', {
        body: JSON.stringify({
          data: { ...formData, language, Email_Opt_In: true },
          pardotUrl,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      if (response.status >= 300) {
        throw new Error('Error sending form data to Pardot');
      }
      events.subscribeSuccess();
      setFormFeedback({
        status: 'success',
        message: submitSuccess,
      });
    } catch {
      events.subscribeFail();
      setFormFeedback({
        status: 'error',
        message: submitError,
      });
    }
  };

  return (
    <ModuleWrapper
      innerProps={{
        direction: 'column',
        justifyContent: ['space-between', null, 'center'],
        minH: ['666px', null, 'auto'],
        p: 0,
        position: 'relative',
        w: 'full',
      }}
      moduleFormat={{
        background: 'transparent',
        spacingBottom: '0',
        spacingTop: '0',
      }}
      outerProps={{ as: 'header', h: 'full', overflow: 'hidden' }}
    >
      <Box px={[2, null, 0]} zIndex={1}>
        <Text
          as="h1"
          color="text.inverse"
          mt={[3, null, 11]}
          textStyle={['5xl', null, '7xl']}
        >
          {title}
        </Text>
        <StructuredContent
          color="text.inverse"
          data={subtitle}
          mt={2}
          textStyle="l"
          w={['full', null, '480px']}
        />
      </Box>
      <Flex
        bg="background.white"
        borderRadius={['16px 16px 0 0', null, '16px']}
        flexDirection="column"
        mb={[null, null, 10]}
        minH="145px"
        mt={4}
        px={2}
        py={3}
        w={['full', null, '480px']}
        zIndex={1}
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormField
            error={errors.email?.message}
            isInvalid={Boolean(errors.email)}
            isRequired
            name="email"
          >
            <Input
              placeholder={input.placeholder}
              type={input.inputType}
              {...register('email', { required: true })}
            />
          </FormField>
          <Button isLoading={isSubmitting} mt={2} type="submit" w="full">
            {buttonText}
          </Button>
          <StructuredContent
            color="text.secondary"
            data={legalText}
            mt={1}
            textAlign="center"
            textStyle="m"
          />
          {formFeedback?.status && <FormFeedback mt={2} {...formFeedback} />}
        </form>
      </Flex>
      <Box
        bottom={['116px', null, 0]}
        position="absolute"
        right={['50%', null, 0]}
        transform={['translate(50%)', null, 'none']}
        w={['full', null, 'auto']}
      >
        <Image data={image.responsiveImage} layout={imageLayout} />
      </Box>
    </ModuleWrapper>
  );
};

export const blogHomeHeaderFragment = `
  fragment blogHomeHeaderFragment on BlogHomeHeaderRecord {
    __typename
    title
    subtitle {
      blocks
      links
      value
    }
    image {
      responsiveImage(imgixParams: {fit: crop, w: 579, h: 512, auto: format}) {
        ...responsiveImageFragment
      }
    }
    input {
      ...formInputFragment
    }
    buttonText
    pardotUrl
    submitError
    submitSuccess
    legalText {
      value
    }
  }
`;
