import { ReactElement } from 'react';

import { Carousel } from '@/modules';
import type {
  DatoBlock,
  DatoCarouselCardList,
  DatoCourseCardBlock,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2';

export type CourseList = DatoBlock<{
  courses: DatoCourseCardBlock[];
  title: string;
}>;

type Props = { data: CourseList } & FlexProps;

const courseToBackground = {
  wd: 'web',
  da: 'data',
  cy: 'cyber',
  ux: 'ux',
};

export const CourseList = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { courses, title } = data;

  const carouselData = {
    moduleFormat: [
      { background: 'light', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    cards: [
      {
        visibleCards: '4',
        cards: courses.map(({ button, course, badge }) => ({
          button,
          content: course.cardDescription,
          hexagonColor:
            courseToBackground[course.code as keyof typeof courseToBackground],
          size: 'l',
          title: course.name,
          ...(badge && {
            leftBadge: [
              {
                text: badge,
                background:
                  courseToBackground[
                    course.code as keyof typeof courseToBackground
                  ],
              },
            ],
          }),
        })),
      },
    ] as [DatoCarouselCardList],
    title,
  };

  return <Carousel data={carouselData} {...flexProps} />;
};

export const courseListFragment = `
  fragment courseListFragment on CourseListRecord {
    __typename
    courses {
      button {
        ...buttonFragment
      }
      badge,
      course {
        cardDescription {
          value
        }
        code
        name
      }
    }
    title
  }
`;
