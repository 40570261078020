import { GridModule } from '@/modules';
import type { DatoGridCardBlock, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HomeGrid = {
  cards: DatoGridCardBlock[];
  subtitle: string;
  title: string;
};

type Props = { data: HomeGrid } & BoxProps;

export const HomeGrid = (props: Props): ReactElement => {
  const {
    data: { cards, subtitle, title },
    ...boxProps
  } = props;

  const gridData = {
    cards,
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };
  return <GridModule as="section" data={gridData} {...boxProps} />;
};

export const homeGridFragment = `
  fragment homeGridFragment on HomeGridRecord {
    __typename
    cards {
      ...gridCardFragment
    }
    subtitle
    title
  }
`;
