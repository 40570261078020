import { useBreakpointValue } from '@ironhack/design-system2/components';
import { useState } from 'react';

import type { DatoButtonBlock, DatoTargetBlock } from '@/lib/datocms';
import type { MenuItem as TMenuItem } from '@/components';
import { NavbarDesktop, NavbarMobile } from '@/components';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type Navbar = {
  button?: [DatoButtonBlock];
  logoTarget: [DatoTargetBlock];
  menu: TMenuItem;
};

type Props = { data: Navbar } & FlexProps;

export const Navbar = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const [menuSelection, setMenuSelection] = useState<number[]>([]);

  const handleFirstLevelMenuClick = (index: number) => () =>
    setMenuSelection((prevSelection) =>
      prevSelection[0] === index ? [] : [index]
    );

  const handleSecondLevelMenuClick = (index: number) => () =>
    setMenuSelection((prevSelection) =>
      prevSelection[1] === index
        ? [prevSelection[0]]
        : [prevSelection[0], index]
    );

  const handleCloseMenu = () => setMenuSelection([]);

  const NavbarComponent = useBreakpointValue(
    {
      base: NavbarMobile,
      lg: NavbarDesktop,
    },
    'lg'
  );

  return (
    <NavbarComponent
      data={data}
      // This could be moved to a context
      menuSelection={menuSelection}
      onCloseMenu={handleCloseMenu}
      onFirstLevelClick={handleFirstLevelMenuClick}
      onSecondLevelClick={handleSecondLevelMenuClick}
      {...flexProps}
    />
  );
};

export const navbarFragment = `
  fragment navbarFragment on NavbarRecord {
    button {
      ...buttonFragment
    }
    logoTarget {
      ...targetFragment
    }
    menu {
      children {
        text
        eventLabel
        target {
          ...targetFragment
        }
        children {
          text
          tag
          eventLabel
          target {
            ...targetFragment
          }
          children {
            text
            eventLabel
            target {
              ...targetFragment
            }
            image {
              responsiveImage(imgixParams: {fit: crop, q: 45, w: 188, h: 188, auto: format, mask: corners, cornerRadius: "6"}) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }
    }
  }
`;
