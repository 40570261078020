import { Storytelling } from '@/modules';
import type { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { BoxProps } from '@ironhack/design-system2';

export type PdpQuality = DatoBlock<{
  content: string;
  title: string;
  image: DatoImage;
}>;

type Props = { data: PdpQuality } & BoxProps;

export const PdpQuality = (props: Props): ReactElement => {
  const { data, ...boxProps } = props;
  const { image, content, title } = data;

  const storytellingData = {
    moduleFormat: [
      {
        background: 'light',
        spacingTop: ['32', null, '80'],
        spacingBottom: ['32', null, '80'],
      },
    ] as [DatoModuleFormat],
    columns: [{ title, subtitle: content }, { image }],
  };

  return <Storytelling data={storytellingData} {...boxProps} />;
};

export const pdpQualityFragment = `
  fragment pdpQualityFragment on PdpQualityRecord {
    __typename
    content
    title
    image {
      responsiveImage(imgixParams: {fit: clip, w: 548, auto: format}) {
        ...responsiveImageFragment
      }
    }
  }
`;
