import { ExpandCollapse } from '@/modules';
import type { DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

type CareerServiceStage = {
  title: string;
  content: StructuredTextGraphQlResponse;
};

export type CareerServiceStageList = {
  stages: CareerServiceStage[];
  subtitle: string;
  title: string;
};

type Props = { data: CareerServiceStageList } & BoxProps;

export const CareerServiceStageList = (props: Props): ReactElement => {
  const {
    data: { stages = [], subtitle, title },
    ...boxProps
  } = props;

  const expandCollapseData = {
    cards: stages,
    filter: false,
    moduleFormat: [
      { background: 'light', spacingBottom: '48', spacingTop: '48' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return (
    <ExpandCollapse as="article" data={expandCollapseData} {...boxProps} />
  );
};

export const careerServiceStageListFragment = `
  fragment careerServiceStageListFragment on CareerServiceStageListRecord {
    __typename
    stages {
      content {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      title
    }
    subtitle
    title
  }
`;
