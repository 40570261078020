import { Flex, Text } from '@ironhack/design-system2/components';
import { ReactElement, useState } from 'react';
import { StructuredTextGraphQlResponse } from 'react-datocms';

import {
  FinancingForm as FinancingFormComponent,
  FormThankYou,
  ModuleWrapper,
} from '@/components';
import type { DatoButtonBlock } from '@/lib/datocms';

export type FinancingForm = {
  buttonText: string;
  chooseBetween: string;
  completeFields: string;
  emailInvalidMessage: string;
  emailLabel: string;
  emailPlaceholder: string;
  emailRequiredMessage: string;
  errorMessage: string;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  firstNameRequiredMessage: string;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  lastNameRequiredMessage: string;
  legalText: StructuredTextGraphQlResponse;
  newsletterLabel: string;
  pardotUrl: string;
  phoneInvalidMessage: string;
  phoneLabel: string;
  phonePlaceholder: string;
  phoneRequiredMessaged: string;
  subtitle: string;
  thankYouText: string;
  thankYouTitle: string;
  title: string;
};

type Props = {
  data: { financingForm: FinancingForm; thankYouButton: [DatoButtonBlock] };
};

export const FinancingForm = (props: Props): ReactElement => {
  const {
    data: { financingForm, thankYouButton },
  } = props;
  const { title, subtitle, thankYouText, thankYouTitle } = financingForm;
  const [showThankYou, setShowThankYou] = useState(false);

  const handleSuccess = () => {
    setShowThankYou(true);
  };

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', alignItems: 'center' }}
      moduleFormat={{
        background: 'light',
        spacingBottom: '40',
        spacingTop: '40',
      }}
      outerProps={{}}
    >
      {!showThankYou && (
        <Flex
          bg={['background.light', null, 'background.white']}
          borderRadius="16px"
          direction="column"
          p={[0, null, 5]}
          w={['full', null, '680px']}
        >
          <Text as="h1" textStyle={['3xl', null, '5xl']}>
            {title}
          </Text>
          <Text mt={4} textStyle="l">
            {subtitle}
          </Text>
          <FinancingFormComponent
            financingForm={financingForm}
            onSuccess={handleSuccess}
          />
        </Flex>
      )}
      {showThankYou && (
        <FormThankYou
          bg={[null, null, 'background.white']}
          borderRadius={[null, null, '2xl']}
          button={thankYouButton}
          p={[null, null, 5]}
          text={thankYouText}
          title={thankYouTitle}
          w={['full', null, '400px']}
        />
      )}
    </ModuleWrapper>
  );
};

export const financingFormFragment = `
  fragment financingFormFragment on FinancingFormModuleRecord {
    __typename
    financingForm {
      buttonText
      chooseBetween
      completeFields
      emailInvalidMessage
      emailLabel
      emailPlaceholder
      emailRequiredMessage
      errorMessage
      firstNameLabel
      firstNamePlaceholder
      firstNameRequiredMessage
      lastNameLabel
      lastNamePlaceholder
      lastNameRequiredMessage
      legalText {
        value
      }
      newsletterLabel
      pardotUrl
      phoneInvalidMessage
      phoneLabel
      phonePlaceholder
      phoneRequiredMessage
      subtitle
      thankYouText
      thankYouTitle
      title
    }
    thankYouButton {
      ...buttonFragment
    }
  }
`;
