import { backgroundToTokens } from '@ironhack/design-system2';
import {
  AspectRatio,
  Box,
  Text,
  VStack,
} from '@ironhack/design-system2/components';
import { Image } from 'react-datocms';

import { Button, ModuleWrapper, StructuredContent } from '@/components';
import type {
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';
import type { WithTracking } from '@/types';

import type {
  BoxProps,
  LinkProps,
  TextProps,
} from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type InfoMedia = {
  button?: [WithTracking<DatoButtonBlock> & Pick<LinkProps, 'rel'>];
  content: StructuredTextGraphQlResponse;
  headingProps?: TextProps;
  image?: DatoImage;
  mapEmbed?: string;
  mediaSide?: 'left' | 'right';
  moduleFormat: [DatoModuleFormat];
  title: string;
};

export const getFrameSrc = (frameString: string): string => {
  const frameSrcRegex = /<iframe .*src="(.*?)".*><\/iframe>/u;
  const [, src] = frameString.match(frameSrcRegex) || [];
  return src;
};

type Props = { data: InfoMedia } & BoxProps;

export const InfoMedia = (props: Props): ReactElement => {
  const {
    data: {
      button: [button] = [],
      content,
      headingProps = {},
      image,
      mapEmbed,
      mediaSide = 'left',
      moduleFormat: [moduleFormat],
      title,
    },
    ...boxProps
  } = props;

  const { color } = backgroundToTokens(moduleFormat.background);

  const direction =
    mediaSide === 'left'
      ? ['column' as const, null, 'row' as const]
      : ['column-reverse' as const, null, 'row-reverse' as const];

  return (
    <ModuleWrapper
      innerProps={{
        align: 'center',
        direction,
        gap: [2, null, 5],
      }}
      moduleFormat={moduleFormat}
      outerProps={{
        as: 'section',
        ...boxProps,
      }}
    >
      {image ? (
        <Box as="figure" w={[null, null, '50%']}>
          <Image data={image.responsiveImage} />
        </Box>
      ) : (
        <AspectRatio
          as="figure"
          borderRadius="8px"
          overflow="hidden"
          ratio={16 / 9}
          w={['100%', null, '50%']}
        >
          <iframe
            allowFullScreen
            frameBorder="0"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src={mapEmbed && getFrameSrc(mapEmbed)}
            style={{ border: 0 }}
            title="campus-location"
          />
        </AspectRatio>
      )}

      <VStack align="left" spacing="4" w={[null, null, '50%']}>
        <VStack align="left" spacing="2">
          <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
            {title}
          </Text>

          <StructuredContent color={color} data={content} />
        </VStack>

        {button && <Button w={['full', null, 'auto']} {...button} />}
      </VStack>
    </ModuleWrapper>
  );
};

export const infoMediaFragment = `
  fragment infoMediaFragment on InfoMediumRecord {
    __typename
    button {
      ...buttonFragment
    }
    content {
      blocks
      links
      value
    }
    image {
      responsiveImage(imgixParams: {fit: crop, w: 550, h: 303, q: 45, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
    mapEmbed
    moduleFormat {
      ...moduleFormatFragment
    }
    title
  }
`;
