import { UserCard } from '@/components';
import { Carousel } from '@/modules';
import type {
  DatoAlumni,
  DatoCarouselCardList,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

export type AlumniList = {
  alumni: DatoAlumni[];
  subtitle: string;
  title: string;
};

type Props = { data: AlumniList } & FlexProps;

export const AlumniList = (props: Props): ReactElement => {
  const {
    data: { alumni = [], subtitle, title },
    ...flexProps
  } = props;

  const cards = alumni.map(
    ({ company: { horizontalTransparentLogo }, ...alum }) => ({
      logos: [horizontalTransparentLogo],
      ...alum,
    })
  );

  const carouselData = {
    cardComponent: UserCard,
    cards: [{ cards }] as [DatoCarouselCardList],
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Carousel as="section" data={carouselData} {...flexProps} />;
};

// Not using companyFragment here so that image height is fixed
export const alumniListFragment = `
  fragment alumniListFragment on AlumniListRecord {
    __typename
    alumni {
      company {
        name
        horizontalTransparentLogo {
          responsiveImage(
            imgixParams: {
              auto: format,
              fit: clip,
              h: 30,
              q: 45,
            }
          ) {
            ...responsiveImageFragment
          }
        }
      }
      givenName
      id
      image {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 160,
            q: 45,
            w: 160,
          }
        ) {
          ...responsiveImageFragment
        }
      }
      jobTitle
      surname
    }
    subtitle
    title
  }
`;
