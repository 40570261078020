import { Hero } from '@/modules';
import type { HeroApplyForm, HeroForm } from '@/components';
import type { DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HomeHero = {
  form: [HeroApplyForm | HeroForm];
  image: DatoImage;
  subtitle: string;
  title: string;
};

type Props = { data: HomeHero } & BoxProps;

export const HomeHero = (props: Props): ReactElement => {
  const {
    data: { form, image, subtitle, title },
    ...boxProps
  } = props;

  const heroData = {
    form,
    image,
    moduleFormat: [
      {
        background: 'transparent',
        spacingBottom: ['24', null, '40'],
        spacingTop: ['16', null, '0'],
      },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Hero as="header" data={heroData} {...boxProps} />;
};

export const homeHeroFragment = `
  fragment homeHeroFragment on HomeHeroRecord {
    __typename
    form {
      ... on HeroFormRecord {
        ...heroFormFragment
      }
      ... on HeroApplyFormRecord {
        ...heroApplyFormFragment
      }
    }
    image {
      responsiveImage(
        imgixParams: { auto: format, fit: crop, h: 500, q: 45, w: 500 }
      ) {
        ...responsiveImageFragment
      }
    }
    subtitle
    title
  }
`;
