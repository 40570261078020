import { Carousel } from '@/modules';
import type {
  DatoBlock,
  DatoCarouselCardList,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type PdpApplyProcessCard = DatoBlock<{
  content: StructuredTextGraphQlResponse;
  title: string;
}>;

export type PdpApplyProcess = DatoBlock<{
  steps: PdpApplyProcessCard[];
  subtitle: string;
  title: string;
}>;

type Props = { data: PdpApplyProcess } & BoxProps;

export const PdpApplyProcess = (props: Props): ReactElement => {
  const {
    data: { steps, subtitle, title },
    ...boxProps
  } = props;

  const carouselData = {
    cards: [
      {
        visibleCards: '4',
        cards: steps.map((step) => ({ ...step, size: 'l' })),
      },
    ] as [DatoCarouselCardList],
    moduleFormat: [
      { background: 'light', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Carousel data={carouselData} {...boxProps} />;
};

export const pdpApplyProcessFragment = `
  fragment pdpApplyProcessFragment on PdpApplyProcessRecord {
    __typename
    steps {
      content {
        value
      }
      title
    }
    subtitle
    title
  }
`;
