import {
  Box,
  Flex,
  HexagonBadge,
  Icon,
  Text,
} from '@ironhack/design-system2/components';
import { useState } from 'react';

import {
  ContactFormFull as ContactFormComponent,
  FormThankYou,
  ModuleWrapper,
} from '@/components';
import { Image } from 'react-datocms';

import { courseToBackground } from '@/lib/utils';
import { MapPin, Send } from '@ironhack/design-system2/icons';

import type {
  DatoButtonBlock,
  DatoCampus,
  DatoCourse,
  DatoImage,
  DatoListWithIconsBlock,
} from '@/lib/datocms';
import type { CourseFormat, CourseTrack } from '@/types';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type ContactFormFull = {
  campuses: Array<Pick<DatoCampus, 'code' | 'name'>>;
  campusLabel: string;
  campusPlaceholder: string;
  campusRequiredMessage: string;
  buttonText: string;
  chooseBetween: string;
  completeFields: string;
  courseLabel: string;
  coursePlaceholder: string;
  courseRequiredMessage: string;
  courses: Array<Pick<DatoCourse, 'code' | 'name'>>;
  courseTypeLabel: string;
  emailInvalidMessage: string;
  emailLabel: string;
  emailPlaceholder: string;
  emailRequiredMessage: string;
  errorMessage: string;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  firstNameRequiredMessage: string;
  inPersonOption: string;
  inquiryLabel: string;
  inquiryPlaceholder: string;
  inquiryRequiredMessage: string;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  lastNameRequiredMessage: string;
  legalText: StructuredTextGraphQlResponse;
  newsletterLabel: string;
  onlineOption: string;
  phoneInvalidMessage: string;
  phoneLabel: string;
  phonePlaceholder: string;
  phoneRequiredMessage: string;
  phoneStep2Text: string;
  step1Cta: string;
  stepsTitle: string;
  backButtonText: string;
  nextButtonText: string;
  step2Title: string;
  step2Subtitle: string;
  step2Image: DatoImage;
  step3Title: string;
  step4Title: string;
  step4RequiredMessage: string;
  step4FullTimeCard: [
    {
      title: string;
      text: string;
      iconList: [DatoListWithIconsBlock];
    }
  ];
  step4PartTimeCard: [
    {
      title: string;
      text: string;
      iconList: [DatoListWithIconsBlock];
    }
  ];
  step5Title: string;
  step5ErrorMessage: string;
  step6Question: string;
  step6MoreDates: string;
  step6RequiredMessage: string;
  subtitle: string;
  thankYouText: string;
  thankYouTitle: string;
  title: string;
};

type Props = {
  data: { contactForm: ContactFormFull; thankYouButton: [DatoButtonBlock] };
};

const Step1 = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <>
      <Text as="h1" textStyle={['2xl', null, '3xl']}>
        {title}
      </Text>
      <Text mt={4} textStyle="l">
        {subtitle}
      </Text>
    </>
  );
};

const Step2 = ({
  title,
  subtitle,
  image,
  name,
}: {
  title: string;
  subtitle: string;
  image: DatoImage;
  name: string;
}) => {
  const processedTitle = title?.replace('{name}', name);

  return (
    <>
      <Text as="h1" textStyle={['2xl', null, '3xl']}>
        {processedTitle}
      </Text>
      <Text mt={4} textStyle="l">
        {subtitle}
      </Text>
      <Box as="figure" w={[null, null, '50%']}>
        <Image data={image?.responsiveImage} />
      </Box>
    </>
  );
};

const GenericStepTitle = ({ title }: { title: string }) => {
  return (
    <>
      <Text as="h1" textStyle={['2xl', null, '3xl']}>
        {title}
      </Text>
    </>
  );
};

const Step6 = ({
  trackCode,
  track,
  format,
  campus,
  questionText,
}: {
  trackCode: CourseTrack;
  track: string;
  format: string;
  campus: string;
  questionText: string;
}) => {
  const badgeColor = courseToBackground[trackCode];

  return (
    <>
      <Flex
        bg={['white', null, 'none']}
        borderRadius={[16, null, 0]}
        boxShadow={['0 0 4px rgb(0 0 0 / 0.08)', null, 'unset']}
        direction="column"
        mb={[3, null, 6]}
        mt={[2, null, 3]}
        p={[2, null, 0]}
      >
        <Flex alignItems="center" gap={1} mb={2}>
          <HexagonBadge
            color={`url(#${badgeColor})`}
            fill={`url(#${badgeColor})`}
            size="24px"
          />
          <Text textStyle="lBold">{track}</Text>
        </Flex>
        <Flex alignItems="center" color="text.secondary" gap={0.5} mb={1}>
          <Icon as={Send} boxSize="20px" />
          <Text textStyle="mBold">{format}</Text>
        </Flex>
        <Flex alignItems="center" color="text.secondary" gap={0.5}>
          <Icon as={MapPin} boxSize="20px" />
          <Text textStyle="mBold">{campus}</Text>
        </Flex>
      </Flex>
      <Text textStyle="lBold">{questionText}</Text>
    </>
  );
};

export const ContactFormFull = (props: Props): ReactElement => {
  const {
    data: { contactForm, thankYouButton },
  } = props;
  const {
    title,
    stepsTitle,
    step2Title,
    step2Subtitle,
    step3Title,
    step2Image,
    step4Title,
    step5Title,
    step6Question,
    subtitle,
    thankYouText,
    thankYouTitle,
  } = contactForm;
  const [showThankYou, setShowThankYou] = useState(false);
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState('');
  const [trackCode, setTrackCode] = useState<CourseTrack | ''>('');
  const [track, setTrack] = useState('');
  const [format, setFormat] = useState<CourseFormat | ''>('');
  const [campus, setCampus] = useState('');

  const handleSuccess = () => {
    setShowThankYou(true);
  };

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', alignItems: 'center' }}
      moduleFormat={{
        background: 'light',
        spacingBottom: '40',
        spacingTop: '40',
      }}
      outerProps={{}}
    >
      {!showThankYou && (
        <Flex
          bg={['background.light', null, 'background.white']}
          borderRadius="16px"
          direction="column"
          p={[0, null, 5]}
          w={['100%', null, '680px']}
        >
          <Text
            as="span"
            textStyle="mBold"
            textTransform="uppercase"
            mb={1}
            color="brand.secondary"
          >
            {stepsTitle.replace('{step}', step.toString())}
          </Text>
          {step === 1 && <Step1 title={title} subtitle={subtitle} />}
          {step === 2 && (
            <Step2
              title={step2Title}
              subtitle={step2Subtitle}
              image={step2Image}
              name={userName}
            />
          )}
          {step === 3 && <GenericStepTitle title={step3Title} />}
          {step === 4 && <GenericStepTitle title={step4Title} />}
          {step === 5 && <GenericStepTitle title={step5Title} />}
          {step === 6 && (
            <Step6
              trackCode={trackCode as CourseTrack}
              track={track}
              format={format}
              campus={campus}
              questionText={step6Question}
            />
          )}
          <ContactFormComponent
            contactForm={contactForm}
            mt={4}
            step={step}
            setStep={setStep}
            onSuccess={handleSuccess}
            onStep1Completion={(name) => setUserName(name)}
            onStep5Completion={(trackCode, track, format, campus) => {
              setTrackCode(trackCode);
              setTrack(track);
              setFormat(format);
              setCampus(campus);
            }}
          />
        </Flex>
      )}
      {showThankYou && (
        <FormThankYou
          bg={[null, null, 'background.white']}
          borderRadius={[null, null, '2xl']}
          button={thankYouButton}
          p={[null, null, 5]}
          text={thankYouText}
          title={thankYouTitle}
          w={['full', null, '400px']}
        />
      )}
    </ModuleWrapper>
  );
};

export const contactFormFullFragment = `
  fragment contactFormFullFragment on ContactFormModuleRecord {
    __typename
    contactForm {
      buttonText
      campuses {
        code
        name
      }
      campusLabel
      campusPlaceholder
      campusRequiredMessage
      chooseBetween
      completeFields
      courseLabel
      coursePlaceholder
      courseRequiredMessage
      courses {
        code
        name
      }
      courseTypeLabel
      emailInvalidMessage
      emailLabel
      emailPlaceholder
      emailRequiredMessage
      errorMessage
      firstNameLabel
      firstNamePlaceholder
      firstNameRequiredMessage
      inPersonOption
      inquiryLabel
      inquiryPlaceholder
      inquiryRequiredMessage
      lastNameLabel
      lastNamePlaceholder
      lastNameRequiredMessage
      legalText {
        value
      }
      newsletterLabel
      onlineOption
      phoneInvalidMessage
      phoneLabel
      phonePlaceholder
      phoneRequiredMessage
      phoneStep2Text
      stepsTitle
      step1Cta
      backButtonText
      nextButtonText
      step2Title
      step2Subtitle
      step2Image {
        responsiveImage {        
          ...responsiveImageFragment
        }
      }
      step3Title      
      step4Title
      step4RequiredMessage
      step4FullTimeCard {
        title
        text
        iconList {
          ...listWithIconsFragment
        }
      }
      step4PartTimeCard {
        title
        text
        iconList {
          ...listWithIconsFragment
        }
      }
      step5Title      
      step5ErrorMessage
      step6Question
      step6MoreDates
      step6RequiredMessage
      subtitle
      thankYouText
      thankYouTitle
      title
    }    
    thankYouButton {
      ...buttonFragment
    }    
  }`;
