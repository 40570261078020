import { Text } from '@ironhack/design-system2/components';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { find, pathEq } from 'ramda';

import {
  CohortSelection,
  CohortSelector as CohortSelectorComponent,
  ModuleWrapper,
  cohortSelectionFragment,
} from '@/components';
import type { CohortSelection as CohortSelectionType } from '@/components';
import { usePageContext } from '@/hooks';
import type { DatoBlock, DatoModuleFormat } from '@/lib/datocms';
import { sendEvent } from '@/lib/gtm';
import { WebCohort } from '@/types';

import type { FlexProps } from '@ironhack/design-system2';

export type CohortSelector = DatoBlock<{
  moduleFormat: [DatoModuleFormat];
  cohortSelection: [CohortSelectionType];
  subtitle?: string;
  title?: string;
}>;

type Props = { data: CohortSelector } & FlexProps;

export const CohortSelector = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    cohortSelection: [cohortSelection],
    moduleFormat: [moduleFormat],
    subtitle,
    title,
  } = data;
  const {
    pageData: { cohorts, gaCategory, gaPageType },
  } = usePageContext<Record<string, never>, { cohorts: WebCohort[] }>();
  const [selectedCohort, setSelectedCohort] = useState<WebCohort>();
  const [isAnimatingCohortSelection, setIsAnimatingCohortSelection] =
    useState<boolean>(false);
  const selectorRef = useRef<HTMLDivElement>();

  // Initialize selected cohort from sessionStorage
  useEffect(() => {
    const storedCohortRaw = sessionStorage.getItem('selectedCohort');
    try {
      const storedCohort = JSON.parse(storedCohortRaw) as WebCohort;
      if (cohorts.some((c) => c.id === storedCohort.id)) {
        setSelectedCohort(storedCohort);
      }
    } catch {}
  }, [cohorts]);

  const handleCohortSelected = (cohortId: string) => {
    const newCohort = find(
      pathEq(['external_ids', 'salesforce'], cohortId),
      cohorts
    ) as WebCohort;
    sendEvent({
      eventCategory: gaCategory,
      eventAction: 'select cohort',
      eventLabel: `${newCohort.campus}::${newCohort.track}::${newCohort.format}`,
      pageType: gaPageType,
    });
    sessionStorage.setItem('selectedCohort', JSON.stringify(newCohort));
    setSelectedCohort(newCohort);

    // Trigger animation
    setIsAnimatingCohortSelection(true);
    setTimeout(() => setIsAnimatingCohortSelection(false), 400);
  };

  const onUnselectClick = () => {
    selectorRef.current.scrollIntoView({ behavior: 'smooth' });
    sendEvent({
      eventAction: 'select sticky cohort',
      eventCategory: gaCategory,
      pageType: gaPageType,
    });
  };

  const onSelectedCohortClick = () => {
    sendEvent({
      eventCategory: gaCategory,
      eventAction: 'click sticky apply now',
      eventLabel: `${selectedCohort.campus}::${selectedCohort.track}::${selectedCohort.format}`,
      pageType: gaPageType,
    });
  };

  return (
    <>
      <ModuleWrapper
        innerProps={{ direction: 'column', gap: 3 }}
        moduleFormat={moduleFormat}
        outerProps={flexProps}
        ref={selectorRef}
      >
        {title && <Text textStyle={['3xl', null, '4xl']}>{title}</Text>}
        {subtitle && <Text textStyle="l">{subtitle}</Text>}
        <CohortSelectorComponent
          cohorts={cohorts}
          radioGroupProps={{
            onChange: handleCohortSelected,
            value: selectedCohort?.external_ids?.salesforce,
          }}
        />
      </ModuleWrapper>
      <CohortSelection
        cohort={selectedCohort}
        isAnimating={isAnimatingCohortSelection}
        onSelectedClick={onSelectedCohortClick}
        onUnselectClick={onUnselectClick}
        {...cohortSelection}
      />
    </>
  );
};

export const cohortSelectorFragment = `
  fragment cohortSelectorFragment on CohortSelectorRecord {
    __typename
    cohortSelector {
      ...cohortSelectorFragment
    }
    moduleFormat{
      ...moduleFormatFragment
    }
    subtitle
    title
  }

  ${cohortSelectionFragment}
`;
