export const datoCmsIdMap = {
  regions: {
    br: '55342477',
    de: '55342474',
    es: '55342455',
    fr: '55342469',
    mx: '55342475',
    nl: '55342471',
    pt: '55342470',
    uk: '55342472',
    us: '55342433',
    ww: '55344020',
  },
  courses: {
    wd: '55656632',
    da: '55714803',
    ux: '55714804',
    cy: '55714809',
  },
};
