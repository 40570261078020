import { useSessionStorage } from 'react-use';
import { useEffect } from 'react';

import { Storytelling } from '@/modules';
import { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';
import { usePageContext } from '@/hooks';
import type { Campus } from '@/types';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type CampusPageHeader = DatoBlock<{
  description: string;
  title: string;
  image: DatoImage;
}>;

type Props = { data: CampusPageHeader } & FlexProps;

export const CampusPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { image, description, title } = data;
  const {
    cmsData: {
      campusInfo: { code },
    },
  } = usePageContext<{ campusInfo: { code: Campus } }>();
  const [campus, setCampus] = useSessionStorage('campus', code);

  useEffect(() => {
    if (campus !== code) {
      setCampus(code);
    }
  }, [campus, code, setCampus]);

  const storytellingData = {
    columns: [{ subtitle: description, title }, { image }],
    headingProps: { as: 'h1' as const, textStyle: ['5xl', null, '7xl'] },
    moduleFormat: [
      {
        background: 'transparent',
        spacingBottom: ['64', null, '80'],
        spacingTop: ['64', null, '80'],
      },
    ] as [DatoModuleFormat],
  };

  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const campusPageHeaderFragment = `
  fragment campusPageHeaderFragment on CampusPageHeaderRecord {
    __typename
    description
    title
    image {
      responsiveImage(imgixParams: {fit: crop, w: 548, h: 310, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
  }
`;
