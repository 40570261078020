import { pluck } from 'ramda';
import { useSessionStorage } from 'react-use';

import { ExpandCollapse } from '@/modules';
import type {
  DatoButtonBlock,
  DatoFinancingOption,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type FinancingPageOptionsList = {
  button: [DatoButtonBlock];
  financingOptions: Array<Omit<DatoFinancingOption, 'compactLogo'>>;
  title: string;
};

type Props = { data: FinancingPageOptionsList } & BoxProps;

export const FinancingPageOptionsList = (props: Props): ReactElement => {
  const {
    data: { button, financingOptions, title },
    ...boxProps
  } = props;
  const [, setFinancingOption] = useSessionStorage('financingOption');

  const cards = financingOptions.map((financingOption) => ({
    button,
    content: financingOption.description,
    filterValues: pluck('name', financingOption.regions),
    image: financingOption.logo,
    onClick: () => setFinancingOption(financingOption.title),
    showImage: financingOption.showImageOnList,
    showTitle: financingOption.showTitleOnList,
    title: financingOption.title,
  }));

  const expandCollapseData = {
    allOpen: true,
    cards,
    filter: false,
    moduleFormat: [
      { background: 'light', spacingBottom: '48', spacingTop: '48' },
    ] as [DatoModuleFormat],
    title,
  };

  return (
    <ExpandCollapse as="section" data={expandCollapseData} {...boxProps} />
  );
};

export const financingPageOptionsListFragment = `
  fragment financingPageOptionsListFragment on FinancingPageOptionsListRecord {
    __typename
    button {
      ...buttonFragment
    }    
    financingOptions {      
      showImageOnList
      showTitleOnList      
      description {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      logo {
        responsiveImage(imgixParams: {fit: clip, q: 45, h: 27, auto: format}) {
          ...responsiveImageFragment
        }
      }
      regions {
        name
      }
      title
    }
    title
  }
`;
