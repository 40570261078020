import { useSessionStorage } from 'react-use';
import { useEffect } from 'react';

import { usePageContext } from '@/hooks';
import { Storytelling } from '@/modules';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';
import type { StorytellingCard } from '@/modules';
import type { Campus, WithTracking } from '@/types';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type PdpPageHeader = DatoBlock<{
  button: [DatoButtonBlock];
  description: StructuredTextGraphQlResponse;
  image: DatoImage;
  tag: string;
  title: string;
}>;

type Props = { data: PdpPageHeader } & FlexProps;

export const PdpPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: [button],
    description,
    image,
    tag,
    title,
  } = data;
  const {
    cmsData: {
      campusInfo: { code },
    },
  } = usePageContext<{ campusInfo: { code: Campus } }>();
  const [campus, setCampus] = useSessionStorage('campus', code);

  useEffect(() => {
    if (campus !== code) {
      setCampus(code);
    }
  }, [campus, code, setCampus]);

  const storytellingData = {
    columns: [
      {
        button: [{ ...button, tracking: { f: 'pd' } }] as [
          WithTracking<DatoButtonBlock>
        ],
        card: [{ content: description }] as [StorytellingCard],
      },
      { image },
    ],
    headingProps: { as: 'h1' as const, textStyle: ['5xl', null, '7xl'] },
    moduleFormat: [
      {
        background: 'transparent',
        spacingBottom: ['32', null, '80'],
        spacingTop: ['32', null, '40'],
      },
    ] as [DatoModuleFormat],
    tag,
    title,
  };

  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const pdpPageHeaderFragment = `
  fragment pdpPageHeaderFragment on PdpPageHeaderRecord {
    __typename
    button {
      ...buttonFragment
    }
    description {
      blocks {
        ...listWithIconsFragment
      }
      links
      value
    }
    image {
      responsiveImage(imgixParams: {fit: crop, w: 548, h: 310, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
    tag
    title
  }
`;
