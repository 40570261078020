import {
  Flex,
  HStack,
  Icon,
  Select,
  Text,
  VStack,
} from '@ironhack/design-system2/components';
import {
  ChevronRight,
  Facebook,
  Instagram,
  Link as LinkIcon,
  Twitter,
  Youtube,
} from '@ironhack/design-system2/icons';
import { useRouter } from 'next/router';
import { ReactElement, useCallback } from 'react';

import { Button, Link, ModuleWrapper } from '@/components';
import type { DatoButtonBlock, DatoLinkBlock, DatoRegion } from '@/lib/datocms';
import { Language } from '@/types';

import type { FlexProps } from '@ironhack/design-system2';

export type Footer = {
  button: [DatoButtonBlock];
  copyright: string;
  facebook: string;
  firstColumnLinks: DatoLinkBlock[];
  firstColumnTitle: string;
  instagram: string;
  privacyPolicy: [DatoLinkBlock];
  region: DatoRegion;
  regions: DatoRegion[];
  secondColumnLinks: DatoLinkBlock[];
  secondColumnTitle: string;
  selectorTitle: string;
  termsOfUse: [DatoLinkBlock];
  thirdColumnLinks: DatoLinkBlock[];
  thirdColumnTitle: string;
  twitter: string;
  youtube: string;
};

type FooterColumnProps = {
  title: string;
  links: DatoLinkBlock[];
  noFollow?: boolean;
};

type Props = { data: Footer } & FlexProps;

const iconMap = {
  'chevron-right': ChevronRight,
  link: LinkIcon,
} as const;

const socialIconMap = {
  instagram: Instagram,
  facebook: Facebook,
  youtube: Youtube,
  twitter: Twitter,
};

const FooterColumn = (props: FooterColumnProps): ReactElement => {
  const { title, links, noFollow } = props;
  return (
    <Flex direction="column">
      <Text textStyle="2xl">{title}</Text>
      <VStack alignItems="flex-start" mt={3} spacing={2}>
        {links.map((link) => {
          const IconComponent =
            iconMap[link.icon?.[0]?.name as keyof typeof iconMap];
          const target = link.target[0];
          const tracking =
            target.route?.page.__typename === 'ContactFormPageRecord'
              ? { f: 'fc' }
              : {};
          return (
            <Link
              _hover={{
                color: 'link.inverse',
                textDecoration: 'underline',
              }}
              color="link.inverse"
              key={link.text}
              {...(noFollow && { rel: 'nofollow' })}
              tracking={tracking}
              w="full"
              {...target}
            >
              <Flex justify="space-between" w={['100%', null, '250px']}>
                {link.text}
                {IconComponent && (
                  <Icon
                    as={IconComponent}
                    boxSize="24px"
                    color="text.inverse"
                  />
                )}
              </Flex>
            </Link>
          );
        })}
      </VStack>
    </Flex>
  );
};

export const Footer = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: [button],
    copyright,
    firstColumnLinks,
    firstColumnTitle,
    privacyPolicy: [privacyPolicy],
    region,
    regions,
    secondColumnLinks,
    secondColumnTitle,
    selectorTitle,
    termsOfUse: [termsOfUse],
    thirdColumnLinks,
    thirdColumnTitle,
  } = data;
  const router = useRouter();
  const socialLinks = ['instagram', 'facebook', 'youtube', 'twitter'] as const;

  const handleRegionChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const regionCode = event.target.value;
      const newRegion = regions.find((r) => r.code === regionCode);
      void router.push({
        pathname: '/[region]/[language]/[[...pathParts]]',
        query: {
          region: newRegion.code,
          language: newRegion.mainLanguage.code.slice(0, 2) as Language,
          pathParts: [],
        },
      });
    },
    [router, regions]
  );

  return (
    <Flex direction="column" {...flexProps}>
      <ModuleWrapper
        innerProps={{ gap: 6, color: 'text.inverse', direction: 'column' }}
        moduleFormat={{
          background: 'dark',
          spacingTop: '48',
          spacingBottom: '48',
        }}
        outerProps={{ as: 'footer' }}
      >
        <Flex
          direction={['column', null, 'row']}
          gap={[5, null, 3]}
          justifyContent="space-between"
          wrap="wrap"
        >
          <FooterColumn links={firstColumnLinks} title={firstColumnTitle} />
          <FooterColumn
            links={secondColumnLinks}
            noFollow
            title={secondColumnTitle}
          />
          <FooterColumn
            links={thirdColumnLinks}
            noFollow
            title={thirdColumnTitle}
          />
          <Flex alignItems="center" direction="column">
            <Text alignSelf="start" mb={2} textStyle="2xl">
              {selectorTitle}
            </Text>
            <Select
              color="text.primary"
              mb={4}
              onChange={handleRegionChange}
              value={region.code}
              w="full"
            >
              {regions.map(({ name, code }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
            {regions.map(({ name, code, mainLanguage }) => (
              // This is needed for SEO purposes
              <a
                href={`/${code}/${mainLanguage.code.slice(0, 2)}`}
                key={code}
                style={{ display: 'none' }}
              >
                {name}
              </a>
            ))}

            <Button
              tracking={{ f: 'fb' }}
              {...button}
              w="full"
              rel="nofollow"
            />
            <HStack mt={5} spacing={3}>
              {socialLinks.map((socialLink) => (
                <a href={data[socialLink]} key={socialLink}>
                  <Icon
                    as={socialIconMap[socialLink]}
                    boxSize="24px"
                    color="text.inverse"
                  />
                </a>
              ))}
            </HStack>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          direction={['column', null, 'row']}
          gap={2}
          justify={['center', null, 'space-between']}
          w="full"
        >
          <Flex gap={2}>
            <Link
              _hover={{
                color: 'link.inverse',
                textDecoration: 'underline',
              }}
              color="link.inverse"
              rel="nofollow"
              {...privacyPolicy.target[0]}
            >
              {privacyPolicy.text}
            </Link>
            <Link
              _hover={{
                color: 'link.inverse',
                textDecoration: 'underline',
              }}
              color="text.inverse"
              rel="nofollow"
              {...termsOfUse.target[0]}
            >
              {termsOfUse.text}
            </Link>
          </Flex>
          <Text align={['center', null, 'start']}>{copyright}</Text>
        </Flex>
      </ModuleWrapper>
    </Flex>
  );
};

export const footerFragment = `
  fragment footerFragment on FooterModuleRecord {
    button {
      ...buttonFragment
    }
    copyright
    facebook
    firstColumnLinks {
      ...linkFragment
    }
    firstColumnTitle
    instagram
    privacyPolicy {
      ...linkFragment
    }
    region {
      code
    }
    secondColumnLinks {
      ...linkFragment
    }
    secondColumnTitle
    selectorTitle
    termsOfUse {
      ...linkFragment
    }
    thirdColumnLinks {
      ...linkFragment
    }
    thirdColumnTitle
    twitter
    youtube
  }
`;
