import { RoundedBanner } from '@/modules';
import type { DatoButtonBlock, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type FaqRoundedBanner = {
  button: [DatoButtonBlock];
  content: StructuredTextGraphQlResponse;
  title: string;
};

type Props = { data: FaqRoundedBanner } & FlexProps;

export const FaqRoundedBanner = (props: Props): ReactElement => {
  const {
    data: { button, content, title },
    ...flexProps
  } = props;

  const roundedBannerData = {
    button,
    content,
    moduleFormat: [
      { background: 'white', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    title,
  };

  return <RoundedBanner as="section" data={roundedBannerData} {...flexProps} />;
};

export const faqRoundedBannerFragment = `
  fragment faqRoundedBannerFragment on FaqRoundedBannerRecord {
    __typename
    button {
      ...buttonFragment
    }
    content {
      value
    }
    title
  }
`;
