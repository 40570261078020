import { ExpandCollapse } from '@/modules';
import type { DatoBlock, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type PdpFaqs = DatoBlock<{
  cards: Array<{
    content: StructuredTextGraphQlResponse;
    title: string;
  }>;
  title: string;
}>;

type Props = { data: PdpFaqs } & BoxProps;

export const PdpFaqs = (props: Props): ReactElement => {
  const {
    data: { cards, title },
    ...boxProps
  } = props;

  const expandCollapseData = {
    cards,
    filter: false,
    moduleFormat: [
      { background: 'light', spacingBottom: '48', spacingTop: '48' },
    ] as [DatoModuleFormat],
    title,
  };

  return <ExpandCollapse data={expandCollapseData} {...boxProps} />;
};

export const pdpFaqsFragment = `
  fragment pdpFaqsFragment on PdpFaqsListRecord {
    __typename
    cards {
      content {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      title
    }
    title
  }
`;
