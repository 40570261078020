import { Flex, Text } from '@ironhack/design-system2/components';
import { useState } from 'react';

import {
  ContactForm as ContactFormComponent,
  FormThankYou,
  ModuleWrapper,
} from '@/components';
import { DatoButtonBlock, DatoCampus, DatoCourse } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type ContactForm = {
  campuses: Array<Pick<DatoCampus, 'code' | 'name'>>;
  campusLabel: string;
  campusPlaceholder: string;
  campusRequiredMessage: string;
  buttonText: string;
  chooseBetween: string;
  completeFields: string;
  courseLabel: string;
  coursePlaceholder: string;
  courseRequiredMessage: string;
  courses: Array<Pick<DatoCourse, 'code' | 'name'>>;
  courseTypeLabel: string;
  emailInvalidMessage: string;
  emailLabel: string;
  emailPlaceholder: string;
  emailRequiredMessage: string;
  errorMessage: string;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  firstNameRequiredMessage: string;
  inPersonOption: string;
  inquiryLabel: string;
  inquiryPlaceholder: string;
  inquiryRequiredMessage: string;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  lastNameRequiredMessage: string;
  legalText: StructuredTextGraphQlResponse;
  newsletterLabel: string;
  onlineOption: string;
  phoneInvalidMessage: string;
  phoneLabel: string;
  phonePlaceholder: string;
  phoneRequiredMessage: string;
  phoneStep2Text: string;
  step2Subtitle: string;
  step2Title: string;
  subtitle: string;
  thankYouText: string;
  thankYouTitle: string;
  title: string;
};

type Props = {
  data: { contactForm: ContactForm; thankYouButton: [DatoButtonBlock] };
};

export const ContactForm = (props: Props): ReactElement => {
  const {
    data: { contactForm, thankYouButton },
  } = props;
  const {
    title,
    step2Title,
    step2Subtitle,
    subtitle,
    thankYouText,
    thankYouTitle,
  } = contactForm;
  const [showThankYou, setShowThankYou] = useState(false);
  const [isStep2, setIsStep2] = useState(false);

  const handleSuccess = () => {
    setShowThankYou(true);
  };

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', alignItems: 'center' }}
      moduleFormat={{
        background: 'light',
        spacingBottom: '40',
        spacingTop: '40',
      }}
      outerProps={{}}
    >
      {!showThankYou && (
        <Flex
          bg={['background.light', null, 'background.white']}
          borderRadius="16px"
          direction="column"
          p={[0, null, 5]}
          w={['full', null, '680px']}
        >
          {!isStep2 && (
            <>
              <Text as="h1" textStyle={['3xl', null, '5xl']}>
                {title}
              </Text>
              <Text mt={4} textStyle="l">
                {subtitle}
              </Text>
            </>
          )}
          {isStep2 && (
            <>
              <Text textStyle="lBold">{step2Title}</Text>
              <Text mt={2} textStyle="l">
                {step2Subtitle}
              </Text>
            </>
          )}
          <ContactFormComponent
            contactForm={contactForm}
            mt={4}
            onStep2={() => setIsStep2(true)}
            onSuccess={handleSuccess}
          />
        </Flex>
      )}
      {showThankYou && (
        <FormThankYou
          bg={[null, null, 'background.white']}
          borderRadius={[null, null, '2xl']}
          button={thankYouButton}
          p={[null, null, 5]}
          text={thankYouText}
          title={thankYouTitle}
          w={['full', null, '400px']}
        />
      )}
    </ModuleWrapper>
  );
};

export const contactFormFragment = `
  fragment contactFormFragment on ContactFormModuleRecord {
    __typename
    contactForm {
      buttonText
      campuses {
        code
        name
      }
      campusLabel
      campusPlaceholder
      campusRequiredMessage
      chooseBetween
      completeFields
      courseLabel
      coursePlaceholder
      courseRequiredMessage
      courses {
        code
        name
      }
      courseTypeLabel
      emailInvalidMessage
      emailLabel
      emailPlaceholder
      emailRequiredMessage
      errorMessage
      firstNameLabel
      firstNamePlaceholder
      firstNameRequiredMessage
      inPersonOption
      inquiryLabel
      inquiryPlaceholder
      inquiryRequiredMessage
      lastNameLabel
      lastNamePlaceholder
      lastNameRequiredMessage
      legalText {
        value
      }
      newsletterLabel
      onlineOption
      phoneInvalidMessage
      phoneLabel
      phonePlaceholder
      phoneRequiredMessage
      phoneStep2Text
      step2Title
      step2Subtitle
      subtitle
      thankYouText
      thankYouTitle
      title
    }
    thankYouButton {
      ...buttonFragment
    }
  }
`;
