'use client';

import {
  Tag as DesignSystemTag,
  Flex,
  SimpleGrid,
  SuperCard,
  Text,
} from '@ironhack/design-system2/components';
import { append, assoc, evolve, path } from 'ramda';
import React from 'react';

import { Button } from '../button/button';
import { StructuredContent } from '../structured-content/structured-content';
import type { DatoButtonBlock } from '@/lib/datocms';

import type {
  ButtonProps,
  FlexProps,
  TextProps,
} from '@ironhack/design-system2';
import type { ReactElement, ReactNode } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

const groupChildrenByType = (
  children: ReactNode[]
): Record<string, ReactNode[]> =>
  React.Children.toArray(children).reduce(
    (acc: Record<string, ReactNode[]>, child: ReactNode) => {
      const elementName = path<string | undefined>(
        ['type', 'componentName'],
        child
      );
      if (elementName) {
        return acc[elementName]
          ? evolve({ [elementName]: append(child) }, acc)
          : assoc(elementName, [child], acc);
      }
      return acc;
    },
    {}
  );

type ActionProps = { button: DatoButtonBlock } & ButtonProps;
const Action = (props: ActionProps): React.ReactElement => {
  const { button, ...buttonProps } = props;

  return (
    <Button
      mt={2}
      w={['full', null, 'fit-content']}
      {...button}
      {...buttonProps}
    />
  );
};
Action.componentName = 'Action';

type CardProps = {
  title?: string;
  content?: StructuredTextGraphQlResponse;
} & Omit<FlexProps, 'content'>;
const Card = (props: CardProps): React.ReactElement => {
  const { title, content, ...flexProps } = props;

  return (
    <SuperCard size="full" {...flexProps}>
      <SuperCard.Body color="text.primary">
        {title && <SuperCard.Title>{title}</SuperCard.Title>}
        {content && <StructuredContent data={content} textStyle="m" />}
      </SuperCard.Body>
    </SuperCard>
  );
};
Card.componentName = 'Card';

type MediaProps = FlexProps;
const Media = (props: MediaProps): React.ReactElement => (
  <Flex borderRadius="16px" overflow="hidden" {...props} />
);
Media.componentName = 'Media';

type SubtitleProps = TextProps;
const Subtitle = (props: SubtitleProps): React.ReactElement => (
  <Text textStyle="l" {...props} />
);
Subtitle.componentName = 'Subtitle';

type ColumnProps = FlexProps;
const Column = (props: ColumnProps): ReactElement => (
  <Flex direction="column" gap={2} {...props} />
);
Column.componentName = 'Column';

type TagProps = { text: string };
const Tag = (props: TagProps): ReactElement => {
  const { text } = props;
  return <DesignSystemTag text={text} />;
};
Tag.componentName = 'Tag';

type TitleProps = TextProps;
const Title = (props: TitleProps): React.ReactElement => (
  <Text noOfLines={2} textStyle={['3xl', null, '5xl']} {...props} />
);
Title.componentName = 'Title';

type StorytellingBaseProps = FlexProps;
export const StorytellingBase = (
  props: StorytellingBaseProps
): ReactElement => {
  const { children, ...flexProps } = props;
  const groups = groupChildrenByType(children as ReactNode[]);
  const hasTagOrTitle = groups.Tag || groups.Title;
  return (
    <Flex flexDirection="column" {...flexProps}>
      {groups?.Tag}
      {groups?.Title}
      {groups.Column && (
        <SimpleGrid
          columns={[1, null, 2]}
          mt={hasTagOrTitle ? [2, null, 3] : 0}
          spacing={[4, null, 3]}
        >
          {groups.Column}
        </SimpleGrid>
      )}
    </Flex>
  );
};

StorytellingBase.Action = Action;
StorytellingBase.Card = Card;
StorytellingBase.Column = Column;
StorytellingBase.Media = Media;
StorytellingBase.Subtitle = Subtitle;
StorytellingBase.Tag = Tag;
StorytellingBase.Title = Title;
