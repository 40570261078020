import { Box, Flex, Grid, Text } from '@ironhack/design-system2/components';
import { compose, modify, replace } from 'ramda';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useNetworkState } from 'react-use';

import { HeroApplyForm, HeroForm, ModuleWrapper } from '@/components';
import type {
  HeroApplyForm as THeroApplyForm,
  HeroForm as THeroForm,
} from '@/components';
import { usePageContext } from '@/hooks';
import type { DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps, TextProps } from '@ironhack/design-system2/components';

export type Hero = {
  form: [THeroApplyForm | THeroForm];
  headingProps?: TextProps;
  image: DatoImage;
  moduleFormat: [DatoModuleFormat];
  subtitle: string;
  title: string;
};

type Props = { data: Hero } & BoxProps;

export const Hero = (props: Props): ReactElement => {
  const {
    data: {
      form: [form],
      headingProps = {},
      image,
      moduleFormat: [moduleFormat],
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const networkState = useNetworkState();
  const {
    params: { region },
  } = usePageContext();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(
    networkState.downlink < 2
  );
  const [heroInView, setHeroInView] = useState<boolean>(false);
  const [isHeroForm, setIsHeroForm] = useState<boolean>(
    form.__typename === 'HeroFormRecord'
  );
  const [isApplyForm, setIsApplyForm] = useState<boolean>(
    form.__typename === 'HeroApplyFormRecord'
  );
  const heroRef = useRef(null);

  // Reserves space for the image while it's being loaded to avoid LS
  useEffect(() => {
    if (heroRef.current) {
      setHeroInView(true);
    }
  }, []);

  // Checks for region changes
  useEffect(() => {
    if (region === 'us') {
      setIsHeroForm(false);
      setIsApplyForm(true);
    } else {
      setIsHeroForm(true);
      setIsApplyForm(false);
    }
  }, [region]);

  const bottomMargin =
    Number.parseInt(
      Array.isArray(moduleFormat.spacingBottom)
        ? moduleFormat.spacingBottom[0]
        : moduleFormat.spacingBottom
    ) / 8;

  const lowQualityBanner: ResponsiveImageType = modify(
    'src',
    compose(
      replace('q=45', 'q=5'),
      replace('h=500', 'h=24'),
      replace('w=500', 'w=24')
    ),
    image.responsiveImage as ResponsiveImageType
  );

  const onLoadHighQualityBanner = () => {
    setIsImageLoaded(true);
    if (showPlaceholder) {
      setTimeout(() => setShowPlaceholder(false), 50);
    }
  };

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', position: 'relative' }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      <Flex
        alignItems="center"
        direction={['column', null, 'row']}
        gap={[4, null, 3]}
      >
        <Flex direction="column" gap={2} justify="center">
          <Text
            as="h1"
            textStyle={['5xl', null, '6xl', '7xl']}
            {...headingProps}
          >
            {title}
          </Text>
          <Text textStyle="lBold">{subtitle}</Text>
        </Flex>

        {!heroInView && (
          <Box
            flex={[null, null, '0 0 40%', '0 0 400px']}
            maxWidth={['275px', '328px', '400px']}
            position="absolute"
            right={0}
            style={{ aspectRatio: '1 / 1' }}
            width={['100%', null, '40%', '400px']}
          />
        )}

        <Grid
          as="span"
          display={['grid', null, !isApplyForm ? 'grid' : 'none']}
          flex={[null, null, '0 0 40%', '0 0 400px']}
          gridTemplateColumns="1fr"
          maxWidth={['275px', '328px', '400px']}
          placeItems="center"
          ref={heroRef}
          style={{ aspectRatio: '1 / 1' }}
          width={['100%', null, '40%', '400px']}
        >
          {showPlaceholder && (
            <Box
              as="figure"
              filter={isImageLoaded ? 'blur(8px)' : 'blur(16px)'}
              gridColumnStart={1}
              gridRowStart={1}
              height="100%"
              minHeight="100%"
              minWidth="100%"
              transition="filter 50ms ease-in-out"
              width="100%"
            >
              <Image data={lowQualityBanner} usePlaceholder={false} />
            </Box>
          )}

          <Box
            as="figure"
            filter={isImageLoaded && !showPlaceholder ? 'none' : 'blur(8px)'}
            gridColumnStart={1}
            gridRowStart={1}
            height="100%"
            minHeight="100%"
            minWidth="100%"
            transition="filter 50ms ease-in-out"
            width="100%"
            zIndex={showPlaceholder ? -1 : 1}
          >
            <Image
              data={image.responsiveImage}
              onLoad={onLoadHighQualityBanner}
              priority
              usePlaceholder={false}
            />
          </Box>
        </Grid>

        {isApplyForm && (
          <HeroApplyForm
            applyForm={form as HeroApplyForm}
            boxSizing="content-box"
            flexShrink={0}
            mb={[-bottomMargin, null, 0]}
            mt={[-4, null, 0]}
            mx={[null, null, 0]}
            w="full"
          />
        )}
      </Flex>

      {isHeroForm && <HeroForm form={form as HeroForm} />}
    </ModuleWrapper>
  );
};

export const heroFragment = `
  fragment heroFragment on HeroRecord {
    __typename
    form {
      ... on HeroFormRecord {
        ...heroFormFragment
      }
      ... on HeroApplyFormRecord {
        ...heroApplyFormFragment
      }
    }
    image {
      responsiveImage(
        imgixParams: { auto: format, fit: crop, h: 500, q: 45, w: 500 }
      ) {
        ...responsiveImageFragment
      }
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;
