import { groupBy, map, mapObjIndexed, pipe, prop, toPairs } from 'ramda';

import { TabbedCarousel } from '@/modules';
import type {
  DatoBlock,
  DatoCardBlock,
  DatoModuleFormat,
  DatoRoute,
} from '@/lib/datocms';
import type { WebCohort } from '@/types';
import type { CarouselTab } from '@/modules';
import { usePageContext } from '@/hooks';
import { commonKeys, getCohortCardIconList } from '@/lib/utils';

import type { BackgroundColor, FlexProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

export type UpcomingCohorts = DatoBlock<{
  applyPageRoute: DatoRoute;
  title: string;
  subtitle: string;
  buttonText: string;
  fomoTag1?: string;
  fomoTag2?: string;
}>;

type Props = { data: UpcomingCohorts; cohorts?: WebCohort[] } & FlexProps;

const courseToBackground: Record<string, BackgroundColor> = {
  wd: 'web',
  da: 'data',
  cy: 'cyber',
  ux: 'ux',
};

export const UpcomingCohorts = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { applyPageRoute, title, subtitle, buttonText, fomoTag1, fomoTag2 } =
    data;
  const {
    pageData: { cohorts, gaCategory, gaPageType },
    locale,
    params: { language },
  } = usePageContext<Record<string, never>, { cohorts: WebCohort[] }>();

  const tabs = pipe<
    [WebCohort[]],
    Record<string, WebCohort[]>,
    Record<string, DatoCardBlock[]>,
    Array<[string, DatoCardBlock[]]>,
    CarouselTab[]
  >(
    groupBy(prop('track')),
    mapObjIndexed(
      map(
        (cohort) =>
          ({
            button: [
              {
                rel: 'nofollow',
                text: buttonText,
                variant: 'primary',
                target: [
                  {
                    route: {
                      path: `${applyPageRoute.path}/c/${cohort.external_ids.salesforce}`,
                    },
                    gaEvent: [
                      {
                        eventCategory: gaCategory,
                        eventAction: 'click cohort',
                        eventLabel: `${cohort.campus}::${cohort.track}::${cohort.format}`,
                        pageType: gaPageType,
                      },
                    ],
                  },
                ],
              },
            ],
            content: getCohortCardIconList(cohort, locale, {
              omitItems: ['price'],
            }),
            hexagonColor: courseToBackground[cohort.track],
            size: 'l',
            subtitle: commonKeys[language][cohort.campus],
            title: commonKeys[language][cohort.track],
          } as DatoCardBlock)
      )
    ),
    toPairs,
    map(([track, cohortCards]) => {
      const cards = cohortCards;
      if (fomoTag1 && cohortCards[0]) {
        cards[0].leftBadge = [
          { text: fomoTag1, background: cohortCards[0].hexagonColor },
        ];
      }
      if (fomoTag2 && cohortCards[1]) {
        cards[0].leftBadge = [
          { text: fomoTag2, background: cohortCards[1].hexagonColor },
        ];
      }
      return {
        title: commonKeys[language][track],
        cards: [{ visibleCards: '4', cards }],
      };
    })
  )(cohorts);

  const carouselData = {
    moduleFormat: [
      { background: 'brand', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    tabs,
    title,
    subtitle,
  };

  return <TabbedCarousel data={carouselData} {...flexProps} />;
};

export const upcomingCohortsFragment = `
  fragment upcomingCohortsFragment on UpcomingCohortListRecord {
    __typename
    applyPageRoute {
      path
    }
    buttonText
    fomoTag1
    fomoTag2
    subtitle
    title
  }
`;
