const responsiveImageFragmentFields = `
  src
  width
  height
  alt
  base64
`;

export const bulletsCardFragment = `
  fragment bulletsCardFragment on BulletsCardRecord {
    content
    image {
      responsiveImage(
        imgixParams: {
          auto: format,
          fit: crop,
          h: 68,
          q: 45,
          w: 68
        }
      ) {
        ...responsiveImageFragment
      }
    }
    tag
    target {
      ...targetFragment
    }
    title
  }
`;

export const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    ${responsiveImageFragmentFields}
  }
`;

const metaTagsFragmentFields = `
  attributes
  content
  tag
`;
export const metaTagsFragment = `
  fragment metaTagsFragment on Tag {
    ${metaTagsFragmentFields}
  }
`;

export const blogBodyBlocksFragmentFields = `
  __typename
  ... on ButtonRecord {
    ...buttonFragment
  }
  ... on ImageBlockRecord {
    id
    image {
      responsiveImage(imgixParams: {fit: crop, w: 800, h: 440, auto: format}) {
        ${responsiveImageFragmentFields}
      }
    }
  }
  ... on VideoBlockRecord {
    id
    videoUrl
  }
`;

export const formInputFragment = `
  fragment formInputFragment on FormInputRecord {
    name
    label
    labelNew {
      value
    }
    inputType
    placeholder
    requiredMessage
    invalidMessage
  }
`;

// This fragment is only needed in the catch-all route, but as it will become
// quite large, it's been taken here to declutter the catch-all route code.
export const routePageFields = `
  ... on AlumniPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on ApplyFormPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on B2bPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on BlogHomePageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on CampaignFormPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on CampusPageRecord {
    campus {
      id
    }
    pageTitle
    region {
      id
    }
  }
  ... on CareerPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on ContactFormPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on FaqPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on FinancingFormPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on FinancingOptionsPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on HomepageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on ModuleSandboxPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on PdpPageRecord {
    campus {
      id
    }
    course {
      id
    }
    pageTitle
    region {
      id
    }
  }
  ... on PrivacyPolicyPageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on SyllabusFormPageRecord {
    course {
      id
    }
    pageTitle
    region {
      id
    }
  }
  ... on TermsOfUsePageRecord {
    pageTitle
    region {
      id
    }
  }
  ... on ThankYouPageRecord {
    pageTitle
    region {
      id
    }
  }
`;

export const gaEventFragment = `
  fragment gaEventFragment on GaEventRecord {
    __typename
    eventCategory
    eventAction
    eventLabel
  }
`;

export const targetFragment = `
  fragment targetFragment on TargetRecord {
    __typename
    route {
      page {
        __typename
      }
      path
    }
    link
    blogArticle {
      previewUrl
    }
    gaEvent {
      ...gaEventFragment
    }
  }
`;

export const linkFragment = `
  fragment linkFragment on LinkRecord {
    __typename
    text
    target {
      ...targetFragment
    }
    icon {
      ...iconFragment
    }
  }
`;

export const iconFragment = `
  fragment iconFragment on IconRecord {
    __typename
    name
    color
    placement
  }
`;

export const buttonFragment = `
fragment buttonFragment on ButtonRecord {
  __typename
  id
  text
  icon {
    ...iconFragment
  }
  target {
    ...targetFragment
  }
  variant
}

${gaEventFragment}
${iconFragment}
`;

export const listWithIconsFragment = `
  fragment listWithIconsFragment on ListWithIconsBlockRecord {
    __typename
    id
    listItems {
      icon {
        ...iconFragment
      }
      text
    }
  }
`;

export const moduleFormatFragment = `
  fragment moduleFormatFragment on ModuleFormatRecord {
    background
    spacingTop
    spacingBottom
  }
`;

export const storyFragment = `
  fragment storyFragment on StoryRecord {
    userName
    userPicture {
      responsiveImage(imgixParams: {fit: crop, q: 45, w: 50, h: 50, auto: format, mask: ellipse}) {
        ...responsiveImageFragment
      }
    }
    userRole,
    company {
      ...companyFragment
    }
    video {
      alt
      video {
        ...videoFragment
      }
    }
  }
`;

export const videoFragment = `
  fragment videoFragment on UploadVideoField {
    duration
    thumbnailUrl
    mp4Url
  }
`;

export const companyFragment = `
  fragment companyFragment on CompanyRecord {
    name
    horizontalBackgroundLogo {
      responsiveImage(imgixParams: {fit: clip, q: 45, w: 126, h: 36, auto: format})  {
        ...responsiveImageFragment
      }
    }
    horizontalTransparentLogo {
      responsiveImage(imgixParams: {fit: clip, q: 45, w: 126, h: 36, auto: format})  {
        ...responsiveImageFragment
      }
    }
    compactBackgroundLogo {
      responsiveImage(imgixParams: {fit: crop, q: 45, w: 36 h: 36, auto: format})  {
        ...responsiveImageFragment
      }
    }
  }
`;

export const cardFragment = `
fragment cardFragment on CardRecord {
  __typename
  id
  button {
    ...buttonFragment
  }
  content {
    blocks
    links
    value
  }
  maxContentLines
  hexagonColor
  leftBadge {
    text
    background
  }
  media {
    responsiveImage {
      alt
      base64
      bgColor
      height
      src
      title
      width
    }
  }
  note
  progress
  rightBadge {
    text
    background
  }
  size
  subtitle
  tags {
    tag
  }
  title
  titleMaxLines
}

${buttonFragment}
`;

export const navbarFragment = `fragment navbarFragment on NavbarRecord {
  button {
    ...buttonFragment
  }
  logoTarget {
    ...targetFragment
  }
  menu {
    children {
      text
      eventLabel
      target {
        ...targetFragment
      }
      children {
        text
        tag
        eventLabel
        target {
          ...targetFragment
        }
        children {
          text
          eventLabel
          target {
            ...targetFragment
          }
          image {
            responsiveImage(imgixParams: {fit: crop, q: 45, w: 188, h: 188, auto: format, mask: corners, cornerRadius: "6"}) {
              ...responsiveImageFragment
            }
          }
        }
      }
    }
  }
}

${buttonFragment}
${targetFragment}
${responsiveImageFragment}`;
