import { RoundedBanner } from '@/modules';
import type {
  DatoButtonBlock,
  DatoFinancingOption,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type HomeFinancing = {
  button: [DatoButtonBlock];
  content: StructuredTextGraphQlResponse;
  financingOptions: Array<Pick<DatoFinancingOption, 'compactLogo'>>;
  title: string;
};

type Props = { data: HomeFinancing } & BoxProps;

export const HomeFinancing = (props: Props): ReactElement => {
  const {
    data: { button, content, financingOptions, title },
    ...boxProps
  } = props;

  const roundedBannerData = {
    button,
    content,
    logos: financingOptions.map((f) => f.compactLogo).filter(Boolean),
    moduleFormat: [
      { background: 'white', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    title,
  };
  return <RoundedBanner as="section" data={roundedBannerData} {...boxProps} />;
};

export const homeFinancingFragment = `
  fragment homeFinancingFragment on HomeFinancingRecord {
    __typename
    button {
      ...buttonFragment
    }
    content {
      value
    }
    financingOptions {
      compactLogo {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 36,
            mask: ellipse,
            q: 45,
            w: 36,
          }
        ) {
          ...responsiveImageFragment
        }
      }
    }
    title
  }
`;
