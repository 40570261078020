import { Storytelling } from '@/modules';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type ThankYouPageHeader = DatoBlock<{
  button: [DatoButtonBlock];
  description: string;
  title: string;
  image: DatoImage;
}>;

type Props = { data: ThankYouPageHeader } & FlexProps;

export const ThankYouPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { button, image, description, title } = data;

  const storytellingData = {
    moduleFormat: [
      { background: 'transparent', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    columns: [{ title, subtitle: description, button }, { image }],
  };

  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const thankYouPageHeaderFragment = `
  fragment thankYouPageHeaderFragment on ThankYouPageHeaderRecord {
    __typename
    button {
      ...buttonFragment
    }
    description
    title
    image {
      responsiveImage(imgixParams: {fit: crop, w: 548, h: 310, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
  }
`;
