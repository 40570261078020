import { InfoMedia } from '@/modules';
import type { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { BoxProps } from '@ironhack/design-system2';

export type SeoContent = DatoBlock<{
  image: DatoImage;
  title: string;
  content: StructuredTextGraphQlResponse;
}>;

type Props = { data: SeoContent } & BoxProps;

export const SeoContent = ({ data, ...boxProps }: Props): ReactElement => {
  const { image, title, content } = data;

  const infoMediaData = {
    content,
    image,
    moduleFormat: [
      {
        background: 'light',
        spacingBottom: '40',
        spacingTop: '40',
      },
    ] as [DatoModuleFormat],
    title,
  };

  return <InfoMedia data={infoMediaData} {...boxProps} />;
};

export const seoContentFragment = `
  fragment seoContentFragment on SeoContentRecord {
    __typename
    image {
      responsiveImage(imgixParams: { auto: format, fit: clip, q: 45, w: 548 }) {
        ...responsiveImageFragment
      }
    }
    title
    content {
      value
    }
  }
`;
