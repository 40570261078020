import type { DatoBlock, DatoCompany, DatoModuleFormat } from '@/lib/datocms';
import { LogoList } from '@/modules';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type CompanyList = DatoBlock<{
  logos: DatoCompany[];
  title: string;
  subtitle: string;
}>;

type Props = { data: CompanyList } & FlexProps;

export const CompanyList = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { title, subtitle, logos = [] } = data;

  const logoListData = {
    headingProps: { as: 'h3' as const },
    logos,
    moduleFormat: [
      {
        background: 'white',
        spacingBottom: ['64', null, '80'],
        spacingTop: ['64', null, '80'],
      },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <LogoList data={logoListData} {...flexProps} />;
};

export const companyListFragment = `
  fragment companyListFragment on CompanyListRecord {
    __typename
    logos {
      ...companyFragment
    }
    subtitle
    title
  }
`;
