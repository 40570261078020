import {
  Pagination,
  SimpleGrid,
  Skeleton,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { useRouter } from 'next/router';
import {
  find,
  has,
  isNil,
  keys,
  mergeLeft,
  // @ts-expect-error -- modifyPath does not exist in @types/ramda
  modifyPath,
  omit,
  pipe,
  propEq,
  reject,
} from 'ramda';
import NextLink from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';

import { fetchBlogArticles } from '@/lib/datocms';
import { ArticleCard, ArticlesFilter, ModuleWrapper } from '@/components';
import type { DatoArticle, DatoBlogCategory } from '@/lib/datocms';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';

import type { Route } from 'nextjs-routes';
import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';
import type { BlogPosting, ItemList, WithContext } from 'schema-dts';

export type QueryParams = { category: string; course: string };
export type ArticlesListPageData = {
  allBlogCategories: DatoBlogCategory[];
  allBlogArticles: DatoArticle[];
  articlesCount: { count: number };
};
export type ArticlesList = {
  articleButtonText: string;
  categoriesPlaceholder: string;
  coursesPlaceholder: string;
  filterTitle: string;
};

type Props = { data: ArticlesList } & FlexProps;

export const ArticlesList = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    articleButtonText,
    categoriesPlaceholder,
    coursesPlaceholder,
    filterTitle,
  } = data;

  const {
    cmsData,
    pageData: { articlesPerPage, defaultFilters, filterMode },
    params: { region, language, pathParts },
  } = usePageContext<
    ArticlesListPageData,
    {
      articlesPerPage: number;
      defaultFilters: QueryParams;
      filterMode: 'and' | 'or';
    }
  >();
  const {
    allBlogCategories,
    allBlogArticles: articles,
    articlesCount: { count: totalArticles },
  } = cmsData;

  const router = useRouter<QueryParams>();
  const { category, course } = mergeLeft(
    router.query ?? {},
    defaultFilters ?? {}
  ) as QueryParams;
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [filteredArticlesCount, setFilteredArticlesCount] =
    useState(totalArticles);
  const [isLoaded, setIsLoaded] = useState(true);
  const has24ArticlesPerPage = articlesPerPage === 24;
  const cardSize: 'l' | '2xl' | '3xl' = useBreakpointValue({
    base: '2xl',
    xl: has24ArticlesPerPage ? '3xl' : 'l',
  });

  const filters = {
    course: {
      placeholder: coursesPlaceholder,
      options: allBlogCategories.filter((c) => c.categoryType === 'course'),
      value: course,
      show: !has('course', defaultFilters),
    },
    category: {
      placeholder: categoriesPlaceholder,
      options: allBlogCategories.filter((c) => c.categoryType !== 'course'),
      value: category,
      show: !has('category', defaultFilters),
    },
  };

  const formattedArticles = filteredArticles.map((article) => ({
    author: article.author,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- modifyPath is missing in @types/ramda
    body: modifyPath(
      ['value', 'document', 'children'],
      (c: Array<{ type: string }>) => [find(propEq('type', 'paragraph'), c)],
      article.body
    ),
    buttonText: articleButtonText,
    categories: article.categories,
    coverImage: article.coverImage,
    publicationDate: article.publicationDate,
    readingTime: article.readingTime,
    series: article.series,
    slug: article.slug,
    title: article.title,
  }));

  const page = pathParts[pathParts.indexOf('page') + 1];
  const showPagination = filteredArticlesCount > articlesPerPage;
  const activePage = Number.parseInt(page) ? Number.parseInt(page) : 1;
  const pagesCount = Math.ceil(filteredArticlesCount / articlesPerPage);
  const queryFilters = pipe(
    reject(isNil),
    omit(keys(defaultFilters))
  )({ course, category }) as QueryParams;
  const pagination = {
    activePage,
    pagesCount,
    getPageUrl: (pageNumber: number): Route<QueryParams> => ({
      pathname: '/[region]/[language]/[[...pathParts]]',
      query: {
        region,
        language,
        pathParts:
          pageNumber === 1
            ? [pathParts[0]]
            : [pathParts[0], 'page', `${pageNumber}`],
        ...queryFilters,
      },
    }),
    link: <NextLink href={{}} legacyBehavior passHref />, // Href will be provided by pagination component
  };

  const resetArticles = useCallback(() => {
    setFilteredArticles(articles);
    setFilteredArticlesCount(totalArticles);
  }, [articles, totalArticles]);

  useEffect(() => {
    const fetchArticles = async (): Promise<void> => {
      setIsLoaded(false);
      const { articles: fetchedArticles, articleCount: fetchedArticlesCount } =
        await fetchBlogArticles(
          region,
          language,
          Number.parseInt(page),
          articlesPerPage,
          reject(isNil, [category, course]),
          filterMode
        );
      setFilteredArticles(fetchedArticles);
      setFilteredArticlesCount(fetchedArticlesCount.count);
      setIsLoaded(true);
    };

    const shouldFetchArticles = Boolean(
      (category || course) && region && language
    );
    if (shouldFetchArticles) {
      void fetchArticles();
    } else {
      resetArticles();
    }
  }, [
    articlesPerPage,
    category,
    course,
    filterMode,
    language,
    page,
    region,
    resetArticles,
  ]);

  const seoStructuredData: WithContext<ItemList> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: formattedArticles.map(
      (article): BlogPosting => ({
        '@type': 'BlogPosting',
        author: [
          {
            '@type': 'Person',
            jobTitle: article.author.jobTitle,
            name: article.author.name,
          },
        ],
        dateModified: new Date(article.publicationDate).toISOString(),
        datePublished: new Date(article.publicationDate).toISOString(),
        headline: article.title,
        image: [article.coverImage.responsiveImage.src],
      })
    ),
  };

  return (
    <>
      <Head>
        {activePage > 1 && (
          <link
            href={`https://www.ironhack.com/${region}/${language}/blog${
              activePage === 2 ? '' : `/page/${activePage - 1}`
            }`}
            rel="prev"
          />
        )}
        {activePage < pagesCount && (
          <link
            href={`https://www.ironhack.com/${region}/${language}/blog/page/${
              activePage + 1
            }`}
            rel="next"
          />
        )}
      </Head>
      <ModuleWrapper
        innerProps={{ gap: [2, null, 3], direction: 'column', ...flexProps }}
        moduleFormat={{
          background: 'light',
          spacingBottom: '40',
          spacingTop: '40',
        }}
      >
        <ArticlesFilter filters={filters} title={filterTitle} />
        <SimpleGrid
          columns={[1, null, 2, null, has24ArticlesPerPage ? 3 : 4]}
          justifyItems="center"
          spacing={[2, null, 3]}
        >
          {formattedArticles.map((article) => (
            <Skeleton
              borderRadius="12px"
              isLoaded={isLoaded}
              key={article.slug}
            >
              <ArticleCard
                onClick={(): void =>
                  sendEvent({
                    eventCategory: 's',
                    eventAction: 'a',
                    eventLabel: 'l',
                  })
                }
                size={cardSize}
                {...article}
              />
            </Skeleton>
          ))}
        </SimpleGrid>
        {showPagination && (
          <Pagination {...pagination} justifyContent="center" w="full" />
        )}
      </ModuleWrapper>
      <Script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(seoStructuredData),
        }}
        id="structured-data-article-list"
        strategy="lazyOnload"
        type="application/ld+json"
      />
    </>
  );
};

export const getArticleListQueryFields = (variables: {
  categories?: boolean;
  perPage: number;
}) => {
  const { categories, perPage } = variables;
  return `
    allBlogCategories(locale: $locale) {
      name
      categoryType
      code
    }
    allBlogArticles(
      filter: {regions: {anyIn: [$regionId]}${
        categories ? `, categories: { anyIn: $categories }` : ''
      } }, locale: $locale, first: "${perPage}",
      orderBy: publicationDate_DESC, skip: $skip
    ) {
      author {
        name
      }
      body {
        value
      }
      categories {
        name
        categoryType
        code
      }
      coverImage {
        responsiveImage(imgixParams: {fit: crop, q: 45, w: 368, h: 160, auto: format}) {
          ...responsiveImageFragment
        }
      }
      series {
        name
      }
      title
      publicationDate
      readingTime
      slug
    }
    articlesCount: _allBlogArticlesMeta(filter: {regions: {anyIn: [$regionId]}${
      categories ? `, categories: { anyIn: $categories }` : ''
    }}, locale: $locale) {
      count
    }
  `;
};

export const articlesListFragment = `
fragment articlesListFragment on ArticlesListRecord {
  __typename
  filterTitle
  coursesPlaceholder
  categoriesPlaceholder
  articleButtonText
}
`;
