import { Storytelling } from '@/modules';
import type { DatoModuleFormat } from '@/lib/datocms';
import type { StorytellingCard } from '@/modules';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type CareerPageHeader = {
  card: [StorytellingCard];
  subtitle: string;
  title: string;
};

type Props = { data: CareerPageHeader } & FlexProps;

export const CareerPageHeader = (props: Props): ReactElement => {
  const {
    data: { card, subtitle, title },
    ...flexProps
  } = props;

  const storytellingData = {
    moduleFormat: [
      { background: 'transparent', spacingBottom: '120', spacingTop: '120' },
    ] as [DatoModuleFormat],
    columns: [{ subtitle, title }, { card }],
  };

  return <Storytelling as="header" data={storytellingData} {...flexProps} />;
};

export const careerPageHeaderFragment = `
  fragment careerPageHeaderFragment on CareerPageHeaderRecord {
    __typename
    card {
      content {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      title
    }
    subtitle
    title
  }
`;
