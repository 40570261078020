import { ModuleWrapper, StructuredContent } from '@/components';
import type { DatoBlock } from '@/lib/datocms';

import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type TermsOfUse = DatoBlock<{
  termsOfUse: { termsOfUse: StructuredTextGraphQlResponse };
}>;

type Props = { data: TermsOfUse } & Omit<FlexProps, 'textStyle' | 'color'>;

export const TermsOfUse = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    termsOfUse: { termsOfUse },
  } = data;
  return (
    <ModuleWrapper
      moduleFormat={{
        spacingTop: '48',
        spacingBottom: '48',
        background: 'white',
      }}
      outerProps={{ as: 'article' }}
    >
      <StructuredContent data={termsOfUse} {...flexProps} />
    </ModuleWrapper>
  );
};

export const termsOfUseFragment = `
  fragment termsOfUseFragment on TermsOfUseBlockRecord {
    __typename
    termsOfUse {
      __typename
      termsOfUse {
        value
      }
    }
  }
`;
