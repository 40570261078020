import { Stats } from '@/modules';
import type { DatoModuleFormat, DatoStat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HomeStats = { stats: DatoStat[] };

type Props = { data: HomeStats } & BoxProps;

export const HomeStats = (props: Props): ReactElement => {
  const {
    data: { stats },
    ...boxProps
  } = props;

  const statsData = {
    moduleFormat: [
      {
        background: 'white',
        spacingBottom: ['40', null, '80'],
        spacingTop: '0',
      },
    ] as [DatoModuleFormat],
    stats,
  };

  return <Stats as="figure" data={statsData} {...boxProps} />;
};

export const homeStatsFragment = `
  fragment homeStatsFragment on HomeStatsModuleRecord {
    __typename
    stats {
      caption
      figure
    }
  }
`;
