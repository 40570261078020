import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@ironhack/design-system2/components';
import { ReactElement } from 'react';

import { Button, CardCarousel, ModuleWrapper } from '@/components';
import type {
  DatoButtonBlock,
  DatoCardBlock,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps, TextProps } from '@ironhack/design-system2';

export type CarouselTab = {
  title: string;
  tag?: string;
  cards: [{ visibleCards: string; cards: DatoCardBlock[] }];
};

export type TabbedCarousel = {
  button?: [DatoButtonBlock];
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  subtitle?: string;
  tabs: CarouselTab[];
  title?: string;
};

type Props = { data: TabbedCarousel } & FlexProps;

export const TabbedCarousel = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: buttonBlock,
    headingProps = {},
    moduleFormat: [moduleFormat],
    subtitle,
    tabs,
    title,
  } = data;
  const button = buttonBlock?.[0];

  if (tabs.length === 0) return null;

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', gap: 3 }}
      moduleFormat={moduleFormat}
      outerProps={{
        ...flexProps,
      }}
    >
      {title && (
        <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
          {title}
        </Text>
      )}
      {subtitle && <Text textStyle="l">{subtitle}</Text>}
      {tabs.length > 0 && (
        <Tabs variant="unstyled">
          <TabList
            gap={3}
            overflowX={['scroll', null, 'auto']}
            sx={{
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {tabs.map(({ title: tabTitle }, index) => (
              <Tab
                _selected={{
                  borderColor: 'fill.interactive',
                  textStyle: 'lBold',
                }}
                borderBottom="4px"
                borderColor="transparent"
                key={`${tabTitle}-${index}`}
                p={0}
                textStyle="l"
                whiteSpace="nowrap"
              >
                {tabTitle}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map(
              (
                { title: tabTitle, cards: [{ cards, visibleCards }] },
                index
              ) => (
                <TabPanel key={`${tabTitle}-${index}`} mt={2} p={0}>
                  <CardCarousel
                    cards={cards}
                    visibleCards={Number.parseInt(visibleCards)}
                  />
                </TabPanel>
              )
            )}
          </TabPanels>
        </Tabs>
      )}
      {button && <Button alignSelf="center" w="fit-content" {...button} />}
    </ModuleWrapper>
  );
};

export const tabbedCarouselFragment = `
  fragment tabbedCarouselFragment on TabbedCarouselRecord {
    __typename
    button {
      ...buttonFragment
    }
    moduleFormat{
      ...moduleFormatFragment
    }
    subtitle
    tabs {
      title
      tag
      cards {
        visibleCards
        cards {
          ...cardFragment
        }
      }
    }
    title
  }
`;
