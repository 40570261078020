import {
  Flex,
  HighlightedCard,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { Fragment } from 'react';
import { Image } from 'react-datocms';

import { Button, Link, ModuleWrapper, StructuredContent } from '@/components';
import type {
  DatoButtonBlock,
  DatoHighlightedCard,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { BoxProps, TextProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type Bullets = {
  button?: [DatoButtonBlock];
  cardSize: 'small' | 'large';
  content: Array<{
    cards?: DatoHighlightedCard[];
    tabs?: Array<{
      cards: DatoHighlightedCard[];
      title: string;
      titleCallout?: string;
    }>;
  }>;
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  subtitle?: string;
  title: string;
};

type Props = { data: Bullets } & BoxProps;

export const Bullets = (props: Props): ReactElement => {
  const {
    data: {
      button: [button] = [],
      cardSize,
      content: [{ tabs, cards }] = [{}],
      headingProps = {},
      moduleFormat: [moduleFormat],
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const colorsMap = ['brand.primary', 'icon.warning', 'fill.new'];
  const isMobile = useBreakpointValue({ base: true, md: false });
  const size = isMobile ? 'full' : cardSize === 'small' ? '2xl' : '5xl';

  const renderCard = (card: DatoHighlightedCard, index: number) => {
    const { content, image, target: [target] = [], ...cardData } = card;
    const Wrap = target
      ? {
          Component: Link,
          props: { withoutDecoration: true, ...target },
        }
      : { Component: Fragment, props: {} };

    return (
      <Wrap.Component key={cardData.title} {...Wrap.props}>
        <HighlightedCard
          borderColor={colorsMap[index % 3]}
          content={
            typeof content === 'string' ? (
              content
            ) : (
              <StructuredContent data={content} textStyle="s" />
            )
          }
          image={image && <Image data={image.responsiveImage} />}
          size={size}
          {...cardData}
        />
      </Wrap.Component>
    );
  };

  return (
    <ModuleWrapper
      innerProps={{
        align: ['start', null, 'center'],
        direction: 'column',
        gap: 3,
        px: [2, null, 10],
      }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      <Text
        as="h2"
        // Make sure it's 60% width in desktop in spite of outer padding
        mx={[0, null, 'calc(20% - var(--chakra-space-6))']}
        textAlign={['start', null, 'center']}
        textStyle={['3xl', null, '4xl']}
        {...headingProps}
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          // Make sure it's 60% width in desktop in spite of outer padding
          mx={[0, null, 'calc(20% - var(--chakra-space-6))']}
          textAlign={['start', null, 'center']}
          textStyle="l"
        >
          {subtitle}
        </Text>
      )}
      {tabs ? (
        <Tabs variant="unstyled" w="full">
          <TabList gap={3} justifyContent={['start', 'center']}>
            {tabs.map(({ title: tabTitle, titleCallout }) => (
              <Tab
                _selected={{ borderColor: 'fill.interactive' }}
                borderBottom="4px"
                borderColor="transparent"
                key={tabTitle}
                p={0}
                textTransform="uppercase"
                {...(titleCallout && { fontWeight: '500' })}
              >
                <Flex align="start" gap={0.5}>
                  {tabTitle}
                  {titleCallout && (
                    <Text color="fill.new" textStyle="sBold">
                      {titleCallout}
                    </Text>
                  )}
                </Flex>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map(({ cards: tabsCards }, index) => (
              <TabPanel
                alignItems="center"
                as={Flex}
                direction="column"
                gap={2}
                key={`highlighted-cards-${index}`}
                p={0}
                pt={3}
              >
                {tabsCards.map(renderCard)}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        cards && (
          <SimpleGrid columns={[1, null, 2, 3]} spacing={2}>
            {cards.map(renderCard)}
          </SimpleGrid>
        )
      )}
      {button && <Button w={['full', null, 'auto']} {...button} />}
    </ModuleWrapper>
  );
};

export const bulletsFragment = `
  fragment bulletsFragment on BulletsModuleBlockRecord {
    __typename
    button {
      ...buttonFragment
    }
    content {
      ... on BulletsModuleCardRecord {
        cards {
          ...bulletsCardFragment
        }
      }
      ... on BulletsModuleTabBlockRecord {
        tabs {
          cards {
            ...bulletsCardFragment
          }
          title
        }
      }
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;
