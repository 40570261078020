import { Stories } from '@/modules';
import type { DatoBlock, DatoModuleFormat, DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { BoxProps } from '@ironhack/design-system2';

export type PdpAlumni = DatoBlock<{
  title: string;
  stories: DatoStory[];
}>;

type Props = { data: PdpAlumni } & BoxProps;

export const PdpAlumni = (props: Props): ReactElement => {
  const { data, ...boxProps } = props;
  const { stories, title } = data;

  const storiesData = {
    moduleFormat: [
      { background: 'light', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    stories,
    title,
  };

  return <Stories data={storiesData} {...boxProps} />;
};

export const pdpAlumniFragment = `
  fragment pdpAlumniFragment on PdpAlumniRecord {
    __typename
    title
    stories {
      ...storyFragment
    }
  }
`;
