// @ts-expect-error @types/ramda does not have modifyPath yet
import { find, modifyPath, propEq } from 'ramda';

import { DatoButtonBlock, DatoCarouselCardList } from '@/lib/datocms';
import { Carousel } from '@/modules';
import type { DatoArticle, DatoBlock, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HighlightedArticleList = DatoBlock<{
  articles: DatoArticle[];
  buttonText: string;
  title: string;
}>;

type Props = { data: HighlightedArticleList } & FlexProps;

export const HighlightedArticleList = (props: Props): ReactElement => {
  const { data } = props;
  const { articles, buttonText, title, ...flexProps } = data;

  const carouselData = {
    moduleFormat: [
      { background: 'light', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    cards: [
      {
        visibleCards: '4',
        cards: articles.map((article) => ({
          button: [
            {
              text: buttonText,
              variant: 'secondary',
              target: [
                {
                  blogArticle: { previewUrl: article.previewUrl },
                  gaEvent: [
                    {
                      eventCategory: 'Blog',
                      eventAction: 'read recommended article',
                      eventLabel: article.title,
                    },
                  ],
                },
              ],
            },
          ] as [DatoButtonBlock],
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- modifyPath is missing in @types/ramda
          content: modifyPath(
            ['value', 'document', 'children'],
            (c: Array<{ type: string }>) => [
              find(propEq('type', 'paragraph'), c),
            ],
            article.body
          ),
          contentAsPlainText: true,
          maxContentLines: 2,
          media: article.coverImage,
          note: `${article.author.name} - ${article.publicationDate}`,
          size: 'l',
          tags: article.categories?.map(({ name }) => ({ tag: name })),
          title: article.title,
          ...(article.series && {
            leftBadge: [{ text: article.series?.name, background: 'pink' }],
          }),
          ...(article.readingTime && {
            rightBadge: [{ text: article.readingTime, background: 'white' }],
          }),
        })),
      },
    ] as [DatoCarouselCardList],
    title,
  };

  return <Carousel data={carouselData} {...flexProps} />;
};

export const highlightedArticleListFragment = `
  fragment highlightedArticleListFragment on HighlightedArticleListRecord {
    __typename
    articles {
      title
      categories {
        name
      }
      author {
        name
      }
      body {
        value
      }
      coverImage {
        responsiveImage {
          ...responsiveImageFragment
        }
      }
      series {
        name
      }
      publicationDate
      readingTime
      previewUrl
    }
    title
    buttonText
  }
`;
