import type { DatoBlock } from '@/lib/datocms';

import { type ReactElement } from 'react';
import { StructuredTextGraphQlResponse } from 'react-datocms';

export type PrivacyPolicy = DatoBlock<{
  privacyPolicy: { privacyPolicy: StructuredTextGraphQlResponse };
}>;

export const PrivacyPolicy = (): ReactElement => {
  return (
    <iframe
      src="https://app.privasee.io/privacy-portal/620f58243422c70014c3f205"
      style={{ height: '100vh', width: '100%' }}
    />
  );
};

export const privacyPolicyFragment = `
  fragment privacyPolicyFragment on PrivacyPolicyBlockRecord {
    __typename
    privacyPolicy {
      __typename
      privacyPolicy {
        value
      }
    }
  }
`;
