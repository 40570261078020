import { ExpandCollapse } from '@/modules';
import type { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type PdpGovernmentInstructions = DatoBlock<{
  instructions: Array<{
    content: StructuredTextGraphQlResponse;
    image: DatoImage;
    showImageOnList: boolean;
    showTitleOnList: boolean;
    title: string;
  }>;
  title: string;
}>;

type Props = { data: PdpGovernmentInstructions } & BoxProps;

export const PdpGovernmentInstructions = (props: Props): ReactElement => {
  const {
    data: { instructions, title },
    ...boxProps
  } = props;

  const cards = instructions.map(
    ({ showImageOnList, showTitleOnList, ...instruction }) => ({
      showImage: showImageOnList,
      showTitle: showTitleOnList,
      ...instruction,
    })
  );

  const expandCollapseData = {
    allOpen: true,
    cards,
    filter: false,
    moduleFormat: [
      { background: 'light', spacingBottom: '48', spacingTop: '48' },
    ] as [DatoModuleFormat],
    title,
  };

  return <ExpandCollapse data={expandCollapseData} {...boxProps} />;
};

export const pdpGovernmentInstructionsFragment = `
  fragment pdpGovernmentInstructionsFragment on PdpGovernmentInstructionsListRecord {
    __typename
    instructions {
      content {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      image {
        responsiveImage(
          imgixParams: { auto: format, fit: clip, h: 80, q: 45 }
        ) {
          ...responsiveImageFragment
        }
      }
      showImageOnList
      showTitleOnList
      title
    }
    title
  }
`;
