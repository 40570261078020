import { Bullets } from '@/modules';
import type { DatoHighlightedCard, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type FinancingPageHeader = {
  cards: DatoHighlightedCard[];
  subtitle: string;
  title: string;
};

type Props = { data: FinancingPageHeader } & BoxProps;

export const FinancingPageHeader = (props: Props): ReactElement => {
  const {
    data: { cards, subtitle, title },
    ...boxProps
  } = props;

  const bulletsData = {
    cardSize: 'small' as const,
    content: [{ cards }],
    moduleFormat: [
      { background: 'transparent', spacingBottom: '80', spacingTop: '80' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Bullets as="header" data={bulletsData} {...boxProps} />;
};

export const financingPageHeaderFragment = `
  fragment financingPageHeaderFragment on FinancingPageHeaderRecord {
    __typename
    cards {
      content
      image {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 68,
            q: 45,
            w: 68
          }
        ) {
          ...responsiveImageFragment
        }
      }
      title
    }
    subtitle
    title
  }
`;
