import { Stories } from '@/modules';
import type { DatoBlock, DatoModuleFormat, DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type AlumniPageHeader = DatoBlock<{
  subtitle: string;
  title: string;
  stories: DatoStory[];
}>;

type Props = { data: AlumniPageHeader } & FlexProps;

export const AlumniPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { stories, subtitle, title } = data;

  const storiesData = {
    moduleFormat: [
      { background: 'brand', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    stories,
    subtitle,
    title,
  };

  return <Stories data={storiesData} {...flexProps} />;
};

export const alumniPageHeaderFragment = `
  fragment alumniPageHeaderFragment on AlumniPageHeaderRecord {
    __typename
    subtitle
    title
    stories {
      ...storyFragment
    }
  }
`;
