import { backgroundToTokens } from '@ironhack/design-system2';
import { Accordion, Flex, Text } from '@ironhack/design-system2/components';
import { uniq } from 'ramda';
import { ReactElement, useCallback, useState } from 'react';

import {
  ExpandCollapseCard,
  ExpandCollapseFilter,
  ModuleWrapper,
} from '@/components';
import type { DatoBlock, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';

export type ExpandCollapse = DatoBlock<{
  allOpen?: boolean;
  cards: ExpandCollapseCard[];
  filter: boolean;
  filterPlaceholder?: string;
  moduleFormat: [DatoModuleFormat];
  subtitle?: string;
  title?: string;
}>;

export type Props = { data: ExpandCollapse } & BoxProps;

export const ExpandCollapse = (props: Props): ReactElement => {
  const {
    data: {
      allOpen,
      cards,
      filter,
      filterPlaceholder,
      moduleFormat: [moduleFormat],
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const filterValues = uniq(cards.flatMap((card) => card.filterValues || []));
  const [filterValue, setFilterValue] = useState<string>();
  const [filteredCards, setFilteredCards] = useState(cards);

  const handleFilterChange = useCallback(
    (newFilterValue: string) => {
      const newFilteredCards = newFilterValue
        ? cards.filter(({ filterValues: cardFilterValues }) =>
            cardFilterValues.includes(newFilterValue)
          )
        : cards;
      setFilteredCards(newFilteredCards);
      setFilterValue(newFilterValue);
    },
    [cards]
  );

  const desktopAlign = filter ? null : 'center';
  const { color } = backgroundToTokens(moduleFormat.background);

  return (
    <ModuleWrapper
      innerProps={{
        align: ['start', null, desktopAlign],
        direction: 'column',
        gap: 3,
        maxW: '720px',
        mx: 'auto',
        px: [2, null, 0],
        textAlign: ['start', null, desktopAlign],
      }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      {title && <Text textStyle="4xl">{title}</Text>}
      {subtitle && <Text textStyle="l">{subtitle}</Text>}

      {filter && (
        <ExpandCollapseFilter
          color={color}
          onChangeValue={handleFilterChange}
          placeholder={filterPlaceholder}
          value={filterValue}
          values={filterValues}
        />
      )}

      <Accordion
        allowMultiple
        as={Flex}
        defaultIndex={allOpen ? filteredCards.map((_, index) => index) : [0]}
        direction="column"
        gap={3}
      >
        {filteredCards.map((card, index) => (
          <ExpandCollapseCard
            card={card}
            key={`expand-collapse-card-${index}`}
          />
        ))}
      </Accordion>
    </ModuleWrapper>
  );
};

export const expandCollapseFragment = `
  fragment expandCollapseFragment on ExpandCollapseModuleRecord {
    __typename
    cards {
      ...expandCollapseCardFragment
    }
    filter
    filterPlaceholder
    moduleFormat {
      ...moduleFormatFragment
    }
    subtitle
    title
  }

  fragment expandCollapseCardFragment on ExpandCollapseCardRecord {
    showImage
    showTitle
    button {
      ...buttonFragment
    }
    content {
      blocks {
        ...listWithIconsFragment
      }
      links
      value
    }
    filterValues
    image {
      responsiveImage(imgixParams: { auto: format, fit: clip, h: 42, q: 45 }) {
        ...responsiveImageFragment
      }
    }
    title
  }
`;
