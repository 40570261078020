import { Bullets } from '@/modules';
import type { DatoHighlightedCard, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HomeAnnouncements = {
  cards: DatoHighlightedCard[];
  title: string;
};

type Props = { data: HomeAnnouncements } & BoxProps;

export const HomeAnnouncements = (props: Props): ReactElement => {
  const {
    data: { cards, title },
    ...boxProps
  } = props;

  const bulletsData = {
    cardSize: 'small' as const,
    content: [{ cards }],
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    title,
  };
  return <Bullets as="section" data={bulletsData} {...boxProps} />;
};

export const homeAnnouncementsFragment = `
  fragment homeAnnouncementsFragment on HomeAnnouncementRecord {
    __typename
    cards {
      ...bulletsCardFragment
    }
    title
  }
`;
