import { UserCard } from '@/components';
import { Carousel } from '@/modules';
import type {
  DatoBlock,
  DatoCarouselCardList,
  DatoModuleFormat,
  DatoTeacher,
} from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { BackgroundColor } from '@ironhack/design-system2';

export type PdpTeachers = DatoBlock<{
  background: BackgroundColor;
  teachers: DatoTeacher[];
  subtitle: string;
  title: string;
}>;

type Props = { data: PdpTeachers } & BoxProps;

export const PdpTeachers = (props: Props): ReactElement => {
  const {
    data: { background, teachers, subtitle, title },
    ...boxProps
  } = props;

  const cards = teachers.map((teacher) => ({
    bio: teacher.bio,
    givenName: teacher.firstName,
    image: teacher.image,
    jobTitle: teacher.jobTitle,
    logos: teacher.companies.map((c) => c.horizontalTransparentLogo),
    surname: teacher.lastName,
  }));

  const carouselData = {
    cardComponent: UserCard,
    cards: [
      {
        visibleCards: '4',
        cards,
      },
    ] as [DatoCarouselCardList],
    moduleFormat: [{ background, spacingTop: '40', spacingBottom: '40' }] as [
      DatoModuleFormat
    ],
    subtitle,
    title,
  };

  return <Carousel data={carouselData} {...boxProps} />;
};

export const pdpTeacherListFragment = `
  fragment pdpTeacherListFragment on PdpTeacherListRecord {
    __typename
    background
    subtitle
    teachers {
      bio
      companies {
        name
        horizontalTransparentLogo {
          responsiveImage(
            imgixParams: {
              auto: format,
              fit: clip,
              h: 20,
              w: 56,
              q: 45,
            }
          ) {
            ...responsiveImageFragment
          }
        }
      }
      firstName
      image {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 160,
            q: 45,
            w: 160,
          }
        ) {
          ...responsiveImageFragment
        }
      }
      jobTitle
      lastName
    }
    title
  }
`;
