import { CohortSelector } from '@/modules';
import type { DatoBlock, DatoModuleFormat, DatoRoute } from '@/lib/datocms';
import { CohortSelection } from '@/components';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';

export type CohortSelectorModule = DatoBlock<{
  applyButtonText: string;
  applyFormRoute: DatoRoute;
  selectionButtonText: string;
  selectionText: string;
  selectionTitle: string;
  subtitle: string;
  title: string;
}>;

type Props = { data: CohortSelectorModule } & FlexProps;

export const CohortSelectorModule = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    applyButtonText,
    applyFormRoute,
    selectionButtonText,
    selectionText,
    selectionTitle,
    subtitle,
    title,
  } = data;

  const cohortSelectorData = {
    cohortSelection: [
      {
        selectedButton: [
          {
            text: applyButtonText,
            variant: 'primary',
            target: [{ route: applyFormRoute }],
          },
        ],
        title: selectionTitle,
        text: selectionText,
        unselectedButtonText: selectionButtonText,
      },
    ] as [CohortSelection],
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <CohortSelector data={cohortSelectorData} {...flexProps} />;
};

export const cohortSelectorModuleFragment = `
  fragment cohortSelectorModuleFragment on CohortSelectorModuleRecord {
    __typename
    applyButtonText
    applyFormRoute {
      path
    }
    selectionButtonText
    selectionText
    selectionTitle
    subtitle
    title
  }
`;
