import { Select, Text } from '@ironhack/design-system2/components';
import {
  append,
  concat,
  evolve,
  is,
  of,
  pipe,
  pluck,
  prop,
  reduce,
  uniq,
  unnest,
} from 'ramda';
import { ComponentType, ReactElement, useMemo, useState } from 'react';

import { Button, CardCarousel, ModuleWrapper } from '@/components';
import type {
  DatoButtonBlock,
  DatoCarouselCardList,
  DatoGenericCardBlock,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps, TextProps } from '@ironhack/design-system2';

export type Carousel = {
  button?: [DatoButtonBlock];
  cardComponent?: ComponentType<{ card: DatoGenericCardBlock } & FlexProps>;
  cards: [DatoCarouselCardList];
  filterField?: string;
  filterPlaceholder?: string;
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  subtitle?: string;
  title?: string;
};

type Props = { data: Carousel } & FlexProps;
type AnyObject = Record<string, unknown>;
type FilterAcc = AnyObject | AnyObject[] | string | string[];
type CardWithFilter = DatoGenericCardBlock & { filterValues?: string[] };

const getFilterValues =
  (filterField: string) =>
  (
    cards: DatoGenericCardBlock[]
  ): { cardsWithFilter: CardWithFilter[]; filterValues: string[] } => {
    if (filterField) {
      return reduce(
        (acc, card: DatoGenericCardBlock) => {
          const filterValues = pipe(
            reduce((innerAcc: FilterAcc, pathPart: string) => {
              const fn = is(Array, innerAcc) ? pluck : prop;

              // @ts-expect-error -- No idea how to fix this one
              return fn(pathPart, innerAcc) as FilterAcc;
            }, card),
            of,
            unnest
          )(filterField.split('.')) as string[];
          return evolve(
            {
              cardsWithFilter: append({ ...card, filterValues }),
              filterValues: pipe(concat(filterValues), uniq),
            },
            acc
          );
        },
        { cardsWithFilter: [], filterValues: [] }
      )(cards);
    }
    return { cardsWithFilter: cards, filterValues: [] };
  };

export const Carousel = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: buttonBlock,
    cardComponent,
    cards: cardsBlock,
    filterField,
    filterPlaceholder,
    headingProps = {},
    moduleFormat: [moduleFormat],
    subtitle,
    title,
  } = data;

  const button = buttonBlock?.[0];
  const { cards = [], visibleCards = '4' } = cardsBlock?.[0] ?? {};

  const [filterString, setFilterString] = useState('');
  const { cardsWithFilter, filterValues } = useMemo(
    () => getFilterValues(filterField)(cards),
    [cards, filterField]
  );
  const filteredCards = useMemo(
    () =>
      filterString
        ? cardsWithFilter.filter(({ filterValues: cardFilterValues }) =>
            cardFilterValues.includes(filterString)
          )
        : cardsWithFilter,
    [cardsWithFilter, filterString]
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filterValue = event.target.value;
    setFilterString(filterValue);
  };

  if (cards.length === 0) return null;

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', gap: 3 }}
      moduleFormat={moduleFormat}
      outerProps={{
        ...flexProps,
      }}
    >
      {title && (
        <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
          {title}
        </Text>
      )}
      {subtitle && <Text textStyle="l">{subtitle}</Text>}
      {filterField && (
        <Select
          onChange={handleFilterChange}
          placeholder={filterPlaceholder}
          w={['full', null, '50%']}
        >
          {filterValues.map((filterValue) => (
            <option key={filterValue} value={filterValue}>
              {filterValue}
            </option>
          ))}
        </Select>
      )}
      {filteredCards.length > 0 && (
        <CardCarousel
          cardComponent={cardComponent}
          cards={filteredCards}
          visibleCards={Number.parseInt(visibleCards)}
        />
      )}
      {button && <Button alignSelf="center" w="fit-content" {...button} />}
    </ModuleWrapper>
  );
};

export const carouselFragment = `
  fragment carouselFragment on CarouselRecord {
    __typename
    button {
      ...buttonFragment
    }
    cards {
      visibleCards
      cards {
        ...cardFragment
      }
    }
    filterField
    filterPlaceholder
    moduleFormat{
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;
