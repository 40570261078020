import {
  Flex,
  Text,
  Wrap,
  WrapItem,
} from '@ironhack/design-system2/components';
import { pluck } from 'ramda';
import { Image } from 'react-datocms';

import { ModuleWrapper } from '@/components';
import type { DatoCompany, DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps, TextProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type Logo = { listLogo: Array<{ image: DatoImage }> };

export type LogoList = {
  headingProps?: TextProps;
  logos: DatoCompany[];
  moduleFormat: [DatoModuleFormat];
  subtitle?: string;
  title?: string;
};

type Props = { data: LogoList } & FlexProps;

export const LogoList = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    headingProps = {},
    logos = [],
    moduleFormat: [moduleFormat],
    subtitle,
    title,
  } = data;

  const images = pluck('horizontalTransparentLogo', logos);

  return (
    <ModuleWrapper
      innerProps={{
        align: 'center',
        direction: 'column',
        gap: 3,
        textAlign: ['left', 'center'],
      }}
      moduleFormat={moduleFormat}
      outerProps={flexProps}
    >
      {(title || subtitle) && (
        <Flex direction="column" gap={1.5} width="100%">
          {title && (
            <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text
              // Make sure it's 60% width in desktop in spite of outer padding
              mx={[0, null, 'calc(20% - var(--chakra-space-6))']}
              textStyle="l"
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      )}

      <Wrap justify="center" spacingX={2} spacingY={3}>
        {images.map(({ responsiveImage }) => (
          <WrapItem alignItems="center" key={responsiveImage.alt}>
            <Image data={responsiveImage} layout="fixed" />
          </WrapItem>
        ))}
      </Wrap>
    </ModuleWrapper>
  );
};

export const logoListFragment = `
  fragment logoListFragment on LogoListRecord {
    __typename
    logos {
      ...companyFragment
    }
    moduleFormat{
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;
