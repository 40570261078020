import { Stories } from '@/modules';
import type { DatoModuleFormat, DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { BoxProps } from '@ironhack/design-system2/components';

export type HomeStories = {
  stories: DatoStory[];
  subtitle: string;
  title: string;
};

type Props = { data: HomeStories } & BoxProps;

export const HomeStories = (props: Props): ReactElement => {
  const {
    data: { stories, subtitle, title },
    ...boxProps
  } = props;

  const storiesData = {
    moduleFormat: [
      { background: 'white', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    stories,
    subtitle,
    title,
  };

  return <Stories data={storiesData} {...boxProps} />;
};

export const homeStoriesFragment = `
  fragment homeStoriesFragment on HomeStoryRecord {
    __typename
    stories {
      ...storyFragment
    }
    subtitle
    title
  }
`;
