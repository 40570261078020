import { Storytelling } from '@/modules';
import { DatoBlock, DatoModuleFormat } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type TermsOfUsePageHeader = DatoBlock<{
  description: string;
  title: string;
}>;

type Props = { data: TermsOfUsePageHeader } & FlexProps;

export const TermsOfUsePageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { description, title } = data;

  const storytellingData = {
    moduleFormat: [
      { background: 'transparent', spacingTop: '80', spacingBottom: '80' },
    ] as [DatoModuleFormat],
    columns: [{ title, subtitle: description }, {}],
  };

  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const termsOfUsePageHeaderFragment = `
  fragment termsOfUsePageHeaderFragment on TermsOfUsePageHeaderRecord {
    __typename
    description
    title
  }
`;
