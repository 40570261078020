import { HStack, Text, UserCard } from '@ironhack/design-system2/components';
import { Image } from 'react-datocms';

import { CancelWrap, ModuleWrapper } from '@/components';
import type { DatoModuleFormat, DatoUserCardBlock } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type UserList = {
  cards: Array<Omit<DatoUserCardBlock, 'logos'>>;
  moduleFormat: [DatoModuleFormat];
  subtitle: string;
  title: string;
};

type Props = { data: UserList } & FlexProps;

export const UserList = (props: Props): ReactElement => {
  const {
    data: {
      cards = [],
      moduleFormat: [moduleFormat],
      subtitle,
      title,
    },
    ...flexProps
  } = props;

  return (
    <ModuleWrapper
      innerProps={{
        align: [null, null, 'center'],
        direction: 'column',
        gap: 1.5,
        textAlign: [null, null, 'center'],
        ...flexProps,
      }}
      moduleFormat={moduleFormat}
    >
      <Text
        as="h2"
        // Make sure it's 60% width in desktop in spite of outer padding
        mx={[0, null, null, 'calc(20% - var(--chakra-space-6))']}
        textStyle="4xl"
      >
        {title}
      </Text>
      <Text
        // Make sure it's 60% width in desktop in spite of outer padding
        mx={[0, null, null, 'calc(20% - var(--chakra-space-6))']}
        textStyle="l"
      >
        {subtitle}
      </Text>
      <CancelWrap below="md">
        <HStack
          align="flex-start"
          as="ul"
          flexWrap={[null, null, 'wrap']}
          justify={[null, null, 'center']}
          px={[null, null, 5]}
          spacing={1.5}
        >
          {cards.map(({ id, image: { responsiveImage }, ...cardProps }) => (
            <UserCard
              as="li"
              bg="transparent"
              image={<Image data={responsiveImage} />}
              key={`user-list-${id}`}
              size="xs"
              {...cardProps}
            />
          ))}
        </HStack>
      </CancelWrap>
    </ModuleWrapper>
  );
};

export const userListFragment = `
  fragment userListFragment on UserListRecord {
    __typename
    cards {
      givenName
      id
      image {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 96,
            q: 45,
            w: 96
          }
        ) {
          ...responsiveImageFragment
        }
      }
      jobTitle
      surname
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;
