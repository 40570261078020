import { createStructuredContent } from '@/lib/utils';
import { InfoMedia } from '@/modules';
import type { DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type HomeCommunity = {
  image: DatoImage;
  subtitle: string;
  title: string;
};

type Props = { data: HomeCommunity } & BoxProps;

export const HomeCommunity = (props: Props): ReactElement => {
  const {
    data: { image, subtitle, title },
    ...boxProps
  } = props;

  const infoMediaData = {
    content: createStructuredContent(subtitle),
    headingProps: { as: 'h2' as const, textStyle: ['5xl', null, '4xl'] },
    image,
    mediaSide: 'right' as const,
    moduleFormat: [
      {
        background: 'white',
        spacingBottom: '0',
        spacingTop: ['32', null, '40'],
      },
    ] as [DatoModuleFormat],
    title,
  };
  return <InfoMedia as="section" data={infoMediaData} {...boxProps} />;
};

export const homeCommunityFragment = `
  fragment homeCommunityFragment on HomeCommunityRecord {
    __typename
    image {
      responsiveImage(imgixParams: { auto: format, fit: clip, q: 45, w: 548 }) {
        ...responsiveImageFragment
      }
    }
    subtitle
    title
  }
`;
