'use client';

import NextLink from 'next/link';
import { Link as DsLink } from '@ironhack/design-system2/components';
import { omit } from 'ramda';

import config from '@/config';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import type { DatoTargetBlock } from '@/lib/datocms';
import type { WithTracking } from '@/types';

import type { LinkProps } from '@ironhack/design-system2/components';
import type { BaseQuery } from 'nextjs-routes';
import type { MouseEvent, ReactElement, ReactNode } from 'react';

type Props = WithTracking<DatoTargetBlock> & {
  withoutDecoration?: boolean;
  children: ReactNode;
} & LinkProps;

const getArticleHref = (previewUrl: string): string => {
  // @ts-expect-error -- Feel free to check this error. I couldn't solve it.
  const query = previewUrl.match(
    /\/(?<region>[a-z]{2})\/(?<language>[a-z]{2})\/blog\/(?<slug>.*)/u
  ).groups as BaseQuery & { slug: string };

  return `/${query.region}/${query.language}/blog/${query.slug}`;
};

const getPageHref = (path: string): string => {
  if (path === '/') {
    return path;
  }

  /* @ts-expect-error: Regex weird error here */
  const query = path.match(
    /\/(?<region>[a-z]{2})\/(?<language>[a-z]{2})\/?(?<pathParts>.*)?/u
  ).groups as BaseQuery & { pathParts: string };

  const pathParts = query.pathParts ? `/${query.pathParts}` : '';

  return `/${query.region}/${query.language}${pathParts}`;
};

export const getTextHref = (target: DatoTargetBlock): string => {
  if (!target) return '';

  const { link, blogArticle, route } = target;
  if (link) return link;

  if (blogArticle) {
    return `${config.baseUrl}${blogArticle.previewUrl}`;
  }

  if (route?.path) {
    return `${config.baseUrl}${route.path}`;
  }

  return config.baseUrl!;
};

export const getHref = (target: DatoTargetBlock | undefined): string => {
  if (!target) return '';

  const { link, blogArticle, route } = target;
  if (link) return link;

  if (blogArticle) {
    return getArticleHref(blogArticle.previewUrl);
  }

  if (route?.path) {
    return getPageHref(route.path);
  }
  return '#';
};

export const Link = (props: Props): ReactElement => {
  const {
    gaEvent: [gaEvent] = [],
    children,
    withoutDecoration,
    onClick,
    tracking,
    ...linkProps
  } = omit(['article', 'blogArticle', 'link', 'route'], props);
  const href = getHref(props);
  const isExternal = href.startsWith('http');
  const variant = withoutDecoration ? 'withoutDecoration' : 'default';
  const {
    pageData: { gaPageType },
  } = usePageContext();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (tracking?.f) sessionStorage.setItem('formReferrer', tracking?.f);
    if (gaEvent) sendEvent({ ...gaEvent, pageType: gaPageType });
    if (onClick) onClick(event);
  };

  return isExternal ? (
    <DsLink
      display="flex"
      href={href}
      isExternal={isExternal}
      onClick={handleClick}
      variant={variant}
      {...(linkProps as LinkProps)}
    >
      {children}
    </DsLink>
  ) : (
    <NextLink href={href} legacyBehavior passHref>
      <DsLink
        isExternal={isExternal}
        onClick={handleClick}
        variant={variant}
        {...(linkProps as LinkProps)}
      >
        {children}
      </DsLink>
    </NextLink>
  );
};
