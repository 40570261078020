import { Image, StructuredTextGraphQlResponse } from 'react-datocms';

import { ModuleWrapper } from '@/components';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';
import { StorytellingBase } from 'app/components/client/storytelling-base/storytelling-base';

import type { FlexProps, TextProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

export type StorytellingCard = {
  title?: string;
  content?: StructuredTextGraphQlResponse;
};
export type Storytelling = DatoBlock<{
  columns: Array<{
    title?: string;
    subtitle?: string;
    image?: DatoImage;
    card?: [StorytellingCard];
    button?: [DatoButtonBlock];
    flexProps?: FlexProps;
  }>;
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  tag?: string;
  title?: string;
}>;

type StorytellingProps = { data: Storytelling } & FlexProps;
export const Storytelling = (props: StorytellingProps): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    columns,
    moduleFormat: [moduleFormat],
    headingProps = {},
    tag,
    title,
  } = data;

  return (
    <ModuleWrapper
      innerProps={flexProps}
      moduleFormat={moduleFormat}
      outerProps={{
        as: 'section',
        ...flexProps,
      }}
    >
      <StorytellingBase>
        {tag && <StorytellingBase.Tag text={tag} />}
        {title && (
          <StorytellingBase.Title {...headingProps}>
            {title}
          </StorytellingBase.Title>
        )}
        {columns.map((column, index) => (
          <StorytellingBase.Column
            key={`${column.title}-${index}`}
            {...column.flexProps}
          >
            {column.title && (
              <StorytellingBase.Title {...headingProps}>
                {column.title}
              </StorytellingBase.Title>
            )}
            {column.subtitle && (
              <StorytellingBase.Subtitle>
                {column.subtitle}
              </StorytellingBase.Subtitle>
            )}
            {column.image && (
              <StorytellingBase.Media>
                <Image data={column.image.responsiveImage} />
              </StorytellingBase.Media>
            )}
            {column.card?.[0] && (
              <StorytellingBase.Card
                content={column.card[0].content}
                title={column.card[0].title}
              />
            )}
            {column.button?.[0] && (
              <StorytellingBase.Action button={column.button[0]} />
            )}
          </StorytellingBase.Column>
        ))}
      </StorytellingBase>
    </ModuleWrapper>
  );
};

export const storytellingFragment = `
  fragment storytellingFragment on StorytellingRecord {
    __typename
    moduleFormat {
      ...moduleFormatFragment
    }
    columns {
      title
      subtitle
      image {
        responsiveImage {
          ...responsiveImageFragment
        }
      }
      card {
        title
        content {
          blocks {
            ...listWithIconsFragment
          }
          links
          value
        }
      }
      button {
        ...buttonFragment
      }
    }
    tag
    title
  }
`;
