import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@ironhack/design-system2/components';

import { CancelWrap, ProgressCard } from '@/components';
import { useModuleBackground } from '@/modules/progress';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';

type Props = {
  cards: ProgressCard[];
} & FlexProps;

export const ProgressCardTabs = (props: Props): ReactElement => {
  const { cards, ...flexProps } = props;
  const [progressFill] = useModuleBackground();

  return (
    <Flex {...flexProps}>
      <Tabs width="full">
        <CancelWrap below="md" withPadding>
          <TabList gap={3} overflowX="auto" whiteSpace="nowrap">
            {cards.map(({ tabTitle }, index) => (
              <Tab
                _selected={{
                  borderColor: 'fill.white',
                }}
                color="text.inverse"
                key={`${tabTitle}-${index}`}
                textTransform="uppercase"
              >
                {tabTitle}
              </Tab>
            ))}
          </TabList>
        </CancelWrap>
        <TabPanels>
          {cards.map((card, index) => {
            const progressValue = Math.floor(
              (100 / cards.length) * (index + 1)
            );
            return (
              <TabPanel key={`${card.tabTitle}-${index}`} mt={3} p={0}>
                <ProgressCard
                  card={{ ...card, progressValue, progressFill }}
                  w="full"
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
