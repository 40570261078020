import { Box, Flex, Text } from '@ironhack/design-system2/components';
import { ReactElement } from 'react';
import { BackgroundColor, backgroundToTokens } from '@ironhack/design-system2';
import { Image } from 'react-datocms';

import { Button, ModuleWrapper, StructuredContent } from '@/components';
import type {
  DatoButtonBlock,
  DatoImage,
  DatoModuleFormat,
} from '@/lib/datocms';
import type { WithTracking } from '@/types';

import type { FlexProps, TextProps } from '@ironhack/design-system2/components';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type RoundedBanner = {
  background?: BackgroundColor;
  button: [WithTracking<DatoButtonBlock>];
  content: StructuredTextGraphQlResponse;
  headingProps?: TextProps;
  logos?: DatoImage[];
  moduleFormat: [DatoModuleFormat];
  title: string;
};

type Props = { data: RoundedBanner } & FlexProps;

export const RoundedBanner = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    background = 'brand',
    button: [button] = [],
    content,
    headingProps = {},
    logos,
    moduleFormat: [moduleFormat],
    title,
  } = data;

  const { bg, color } = backgroundToTokens(background);

  return (
    <ModuleWrapper innerProps={{ ...flexProps }} moduleFormat={moduleFormat}>
      <Flex
        bg={bg}
        borderRadius="16px"
        color={color}
        direction="column"
        pb={3}
        pt={2}
        px={[2, null, 3]}
        w="full"
      >
        <Text as="h2" textStyle={['3xl', null, '4xl']} {...headingProps}>
          {title}
        </Text>
        <StructuredContent color={color} data={content} maxW="65ch" mt={2} />
        {logos && (
          <Flex gap={1.5} mt={4}>
            {logos.map((logo) => (
              <Box
                borderColor="white"
                borderRadius="full"
                borderWidth="2px"
                key={logo.responsiveImage.alt}
                p={0.5}
              >
                <Image data={logo.responsiveImage} />
              </Box>
            ))}
          </Flex>
        )}
        {button && (
          <Button w={['full', null, 'fit-content']} {...button} mt={4} />
        )}
      </Flex>
    </ModuleWrapper>
  );
};

export const roundedBannerFragment = `
  fragment roundedBannerFragment on RoundedBannerRecord {
    __typename
    button {
      ...buttonFragment
    }
    content {
      value
    }
    logos {
      responsiveImage(
        imgixParams: {
          fit: crop,
          q: 45,
          w: 36,
          h: 36,
          auto: format,
          mask: ellipse
        }
      ) {
        ...responsiveImageFragment
      }
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    title
  }
`;
