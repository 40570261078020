import {
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';

import { GridCard, ModuleWrapper } from '@/components';
import type { DatoGridCardBlock, DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps, TextProps } from '@ironhack/design-system2/components';
import type { ComponentProps, ReactElement } from 'react';

export type GridModule = {
  cards: DatoGridCardBlock[];
  headingProps?: TextProps;
  moduleFormat: [DatoModuleFormat];
  subtitle: string;
  title: string;
};

type Props = { data: GridModule } & BoxProps;

export const GridModule = (props: Props): ReactElement => {
  const {
    data: {
      cards,
      headingProps = {},
      moduleFormat: [moduleFormat],
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const cardOrientation = useBreakpointValue({
    base: 'vertical',
    sm: 'horizontal',
  });

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', gap: 3 }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      <Text as="h2" textStyle="3xl" {...headingProps}>
        {title}
      </Text>
      <Text textStyle="l">{subtitle}</Text>
      <Grid
        columnGap={2}
        rowGap={[2, null, 3]}
        templateColumns="repeat(auto-fill, 156px)"
      >
        {cards.map(({ id, image, ...card }) => (
          <GridItem
            key={id}
            {...(image && { colSpan: [1, 2], rowSpan: [2, 1] })}
          >
            <GridCard
              card={{ image, ...card }}
              {...(image && {
                orientation: cardOrientation as ComponentProps<
                  typeof GridCard
                >['orientation'],
              })}
            />
          </GridItem>
        ))}
      </Grid>
    </ModuleWrapper>
  );
};

export const gridModuleFragment = `
  fragment gridModuleFragment on GridRecord {
    __typename
    cards {
      ...gridCardFragment
    }
    moduleFormat {
      ...moduleFormatFragment
    }
    subtitle
    title
  }
`;

export const gridCardFragment = `
  fragment gridCardFragment on GridCardRecord {
    content
    id
    image {
      responsiveImage(
        imgixParams: { auto: format, fit: crop, h: 156, q: 45, w: 156 }
      ) {
        ...responsiveImageFragment
      }
    }
    tag {
      colorScheme
      text
    }
    target {
      ...targetFragment
    }
    title
  }
`;
