import { Flex, Text } from '@ironhack/design-system2/components';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import {
  ApplyForm as ApplyFormComponent,
  ModuleWrapper,
  StructuredContent,
} from '@/components';
import { useExperimentApplication, usePageContext } from '@/hooks';
import type { WebCohort } from '@/types';
import { commonKeys, getCohortCardIconList } from '@/lib/utils';
import { hackCohortFor20Weeks } from '@/lib/utils/experiments';
import type { ApplyFormPageQuery } from '@/page-data-loaders/apply-form-page';

import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

export type ApplyForm = {
  buttonText: string;
  chooseBetween: string;
  completeFields: string;
  emailInvalidMessage: string;
  emailLabel: string;
  emailPlaceholder: string;
  emailRequiredMessage: string;
  errorMessage: string;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  firstNameRequiredMessage: string;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  lastNameRequiredMessage: string;
  legalText: StructuredTextGraphQlResponse;
  newsletterLabel: string;
  phoneInvalidMessage: string;
  phoneLabel: string;
  phonePlaceholder: string;
  phoneRequiredMessage: string;
  phoneStep2Text: string;
  referralLabel: string;
  referralOptions: Array<{ key: string; text: string }>;
  referralPlaceholder: string;
  step2Subtitle: string;
  step2Title: string;
  title: string;
};

type Props = { data: { applyForm: ApplyForm } };

export const ApplyForm = (props: Props): ReactElement => {
  const {
    data: { applyForm },
  } = props;
  const { title, step2Subtitle, step2Title } = applyForm;
  const [isStep2, setIsStep2] = useState(false);
  const router = useRouter<{ c?: string }>();
  const {
    params: { language, region },
    locale,
    pageData,
    cmsData: {
      page: { thankYouPage },
    },
  } = usePageContext<ApplyFormPageQuery, { cohort: WebCohort }>();
  let { cohort } = pageData;

  const { isNewFormat } = useExperimentApplication(
    'valueProp',
    cohort.external_ids.salesforce
  );
  if (isNewFormat) {
    cohort = hackCohortFor20Weeks(cohort);
  }

  // Don't show end date and number of weeks until client takes over
  // (it depends on session storage)
  const [showEndPeriod, setShowEndPeriod] = useState(false);
  useEffect(() => setShowEndPeriod(true), [setShowEndPeriod]);

  const cohortIconList = getCohortCardIconList(cohort, locale, {
    showEndPeriod,
  });

  const handleSuccess = () => {
    const pathParts = thankYouPage.path.split('/').slice(3);
    void router.push({
      pathname: '/[region]/[language]/[[...pathParts]]',
      query: { region, language, pathParts },
    });
  };

  return (
    <ModuleWrapper
      innerProps={{ direction: 'column', alignItems: 'center' }}
      moduleFormat={{
        background: 'light',
        spacingBottom: '40',
        spacingTop: '40',
      }}
      outerProps={{}}
    >
      <Flex
        bg={['background.light', null, 'background.white']}
        borderRadius="16px"
        direction="column"
        p={[0, null, 5]}
        w={['full', null, '680px']}
      >
        <Text as="h1" textStyle={['3xl', null, '5xl']}>
          {title}
        </Text>
        {!isStep2 && (
          <Flex
            bg="background.white"
            borderRadius="16px"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.16)"
            direction="column"
            gap={1}
            mt={[3, null, 6]}
            p={2}
            w={['full', null, '268px']}
          >
            <Text textStyle="2xl">{commonKeys[language][cohort.track]}</Text>
            <Text textStyle="xlBold">
              {commonKeys[language][cohort.campus]}
            </Text>
            <StructuredContent data={cohortIconList} textStyle="m" />
          </Flex>
        )}
        {isStep2 && (
          <>
            <Text mt={4} textStyle="lBold">
              {step2Title}
            </Text>
            <Text mt={2} textStyle="l">
              {step2Subtitle}
            </Text>
          </>
        )}
        <ApplyFormComponent
          applyForm={applyForm}
          cohort={cohort}
          mt={4}
          onStep2={() => setIsStep2(true)}
          onSuccess={handleSuccess}
        />
      </Flex>
    </ModuleWrapper>
  );
};

export const applyFormFragment = `
  fragment applyFormFragment on ApplyFormModuleRecord {
    __typename
    applyForm {
      buttonText
      chooseBetween
      completeFields
      emailInvalidMessage
      emailLabel
      emailPlaceholder
      emailRequiredMessage
      errorMessage
      firstNameLabel
      firstNamePlaceholder
      firstNameRequiredMessage
      lastNameLabel
      lastNamePlaceholder
      lastNameRequiredMessage
      legalText {
        value
      }
      newsletterLabel
      phoneInvalidMessage
      phoneLabel
      phonePlaceholder
      phoneRequiredMessage
      phoneStep2Text
      referralLabel
      referralOptions {
        key
        text
      }
      referralPlaceholder
      step2Subtitle
      step2Title
      title
    }
  }
`;
