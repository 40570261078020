import { Card, Flex, Text } from '@ironhack/design-system2/components';
import { useRef } from 'react';

import {
  CohortSelection,
  ComparisonCohortCard,
  ListWithIcons,
  ModuleWrapper,
  TrackBadge,
} from '@/components';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import type { DatoListWithIconsBlock, DatoRoute } from '@/lib/datocms';
import type { PdpPageQuery } from '@/page-data-loaders/pdp-page';
import type { CourseTrack, WebCohort } from '@/types';

import type { BoxProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

type CourseFormatCard = {
  bulletList: [DatoListWithIconsBlock];
  subtitle: string;
  title: string;
};

export type ComparisonCohortSelector = {
  applyButtonText: string;
  applyFormRoute: Pick<DatoRoute, 'path'>;
  bulletList: [DatoListWithIconsBlock];
  courseFormatCards: [CourseFormatCard, CourseFormatCard];
  newCourseFormat: {
    liveClassesText: string;
    newFormatText: string;
    selfGuidedText: string;
  } | null;
  stickyBarButtonText: string;
  stickyBarText: string;
  stickyBarTitle: string;
  subtitle: string;
  title: string;
};

type Props = { data: ComparisonCohortSelector } & BoxProps;

export const ComparisonCohortSelector = (props: Props): ReactElement => {
  const {
    data: {
      applyButtonText,
      applyFormRoute,
      bulletList: [bulletList],
      courseFormatCards,
      newCourseFormat,
      stickyBarButtonText,
      stickyBarText,
      stickyBarTitle,
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const {
    cmsData: { course },
    pageData: { cohorts, gaCategory, gaPageType },
  } = usePageContext<PdpPageQuery, { cohorts: WebCohort[] }>();

  const selectorRef = useRef<HTMLDivElement>();

  const onUnselectClick = () => {
    selectorRef.current?.scrollIntoView({ behavior: 'smooth' });
    sendEvent({
      eventAction: 'select sticky cohort',
      eventCategory: gaCategory,
      pageType: gaPageType,
    });
  };

  const cardFormats = ['pt', 'ft'] as const;

  return (
    <>
      <ModuleWrapper
        innerProps={{ direction: 'column' }}
        moduleFormat={{
          background: 'light',
          spacingBottom: ['56', null, '80'],
          spacingTop: '40',
        }}
        outerProps={boxProps}
        ref={selectorRef}
      >
        <Text textStyle={['3xl', null, '4xl']}>{title}</Text>
        <Text mt={2} textStyle="l">
          {subtitle}
        </Text>
        <ListWithIcons
          iconProps={{ mr: 1 }}
          list={bulletList}
          mt={4}
          textStyle="l"
        />

        <Flex
          align="start"
          direction={['column', null, 'row']}
          gap={[2, null, 3]}
          mt={6}
        >
          {courseFormatCards.map((formatCard, index) => (
            <Card
              borderBottomRadius="none"
              key={formatCard.title}
              w={['100%', null, '50%']}
            >
              <Flex direction="column" pb={6} pt={2} px={3}>
                <Flex alignItems="center" gap={2}>
                  <TrackBadge
                    format={cardFormats[index]}
                    size={7}
                    track={course.code as CourseTrack}
                  />
                  <Text textStyle="2xl">{formatCard.title}</Text>
                </Flex>
                <Text color="text.secondary" mt={1.5}>
                  {formatCard.subtitle}
                </Text>
                <ListWithIcons
                  color="text.secondary"
                  iconProps={{ mr: 1 }}
                  list={formatCard.bulletList[0]}
                  mt={5}
                  px={[null, null, 1]}
                />
              </Flex>
              {cohorts
                .filter((cohort) => cohort.format === cardFormats[index])
                .map((cohort) => (
                  <ComparisonCohortCard
                    buttonBasePath={applyFormRoute.path}
                    buttonText={applyButtonText}
                    cohort={cohort}
                    key={cohort.id}
                    newCourseFormat={newCourseFormat}
                  />
                ))}
            </Card>
          ))}
        </Flex>
      </ModuleWrapper>
      <CohortSelection
        onUnselectClick={onUnselectClick}
        text={stickyBarText}
        title={stickyBarTitle}
        unselectedButtonText={stickyBarButtonText}
      />
    </>
  );
};

export const comparisonCohortSelectorFragment = `
  fragment comparisonCohortSelectorFragment on ComparisonCohortSelectorRecord {
    __typename
    applyButtonText
    applyFormRoute {
      path
    }
    bulletList {
      ...listWithIconsFragment
    }
    courseFormatCards {
      bulletList {
        ...listWithIconsFragment
      }
      subtitle
      title
    }
    newCourseFormat {
      liveClassesText
      newFormatText
      selfGuidedText
    }
    stickyBarButtonText
    stickyBarText
    stickyBarTitle
    subtitle
    title
  }
`;
