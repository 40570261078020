import { RoundedBanner } from '@/modules';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoModuleFormat,
} from '@/lib/datocms';
import type { WithTracking } from '@/types';

import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type AlumniBanner = DatoBlock<{
  content: StructuredTextGraphQlResponse;
  title: string;
  button: [DatoButtonBlock];
}>;

type Props = { data: AlumniBanner } & FlexProps;

export const AlumniBanner = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const {
    button: [button],
    content,
    title,
  } = data;

  const roundedBannerData = {
    moduleFormat: [
      { background: 'white', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    button: [{ ...button, tracking: { f: 'al' } }] as [
      WithTracking<DatoButtonBlock>
    ],
    content,
    title,
  };

  return <RoundedBanner data={roundedBannerData} {...flexProps} />;
};

export const alumniBannerFragment = `
  fragment alumniBannerFragment on AlumniBannerRecord {
    __typename
    content {
      value
    }
    title
    button {
      ...buttonFragment
    }
  }
`;
