import { Bullets } from '@/modules';
import type { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';
import { commonKeys } from '@/lib/utils';
import { usePageContext } from '@/hooks';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type DayAtIronhackActivity = DatoBlock<{
  activity: string;
  image: DatoImage;
  times: string;
}>;

export type PdpDayAtIronhack = DatoBlock<{
  fullTimeDay: DayAtIronhackActivity[];
  partTimeDay: DayAtIronhackActivity[];
  subtitle: string;
  title: string;
}>;

type Props = { data: PdpDayAtIronhack } & BoxProps;

export const PdpDayAtIronhack = (props: Props): ReactElement => {
  const {
    data: { fullTimeDay, partTimeDay, subtitle, title },
    ...boxProps
  } = props;
  const {
    params: { language },
  } = usePageContext();

  const ftTab = {
    title: commonKeys[language].ft.toUpperCase(),
    cards: fullTimeDay.map((activity) => ({
      image: activity.image,
      title: activity.activity,
      content: activity.times,
    })),
  };

  const ptTab = {
    title: commonKeys[language].pt.toUpperCase(),
    cards: partTimeDay.map((activity) => ({
      image: activity.image,
      title: activity.activity,
      content: activity.times,
    })),
  };

  const bulletsData = {
    cardSize: 'large' as const,
    content: [{ tabs: [ftTab, ptTab] }],
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Bullets data={bulletsData} {...boxProps} />;
};

export const pdpDayAtIronhackFragment = `
  fragment pdpDayAtIronhackFragment on PdpDayAtIronhackRecord {
    __typename
    fullTimeDay {
      ...activityCardFragment
    }
    partTimeDay {
      ...activityCardFragment
    }
    subtitle
    title
  }

  fragment activityCardFragment on PdpDayAtIronhackCardRecord {
    activity
    image {
      responsiveImage(
        imgixParams: {
          auto: format,
          fit: crop,
          h: 68,
          q: 45,
          w: 68
        }
      ) {
        ...responsiveImageFragment
      }
    }
    times
  }
`;
