import { RoundedBanner } from '@/modules';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoFinancingOption,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { ReactElement } from 'react';
import type { BackgroundColor, BoxProps } from '@ironhack/design-system2';

export type PdpFinancing = DatoBlock<{
  background: BackgroundColor;
  button: [DatoButtonBlock];
  financingOptions: Array<Pick<DatoFinancingOption, 'compactLogo'>>;
  content: StructuredTextGraphQlResponse;
  title: string;
}>;

type Props = { data: PdpFinancing } & BoxProps;

export const PdpFinancing = (props: Props): ReactElement => {
  const { data, ...boxProps } = props;
  const { button, content, financingOptions, background, title } = data;

  const roundedBannerData = {
    background,
    button,
    content,
    logos: financingOptions.map((f) => f.compactLogo).filter(Boolean),
    moduleFormat: [
      { background: 'white', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    title,
  };

  return <RoundedBanner data={roundedBannerData} {...boxProps} />;
};

export const pdpFinancingFragment = `
  fragment pdpFinancingFragment on PdpFinancingRecord {
    __typename
    background
    button {
      ...buttonFragment
    }
    content {
      value
    }
    financingOptions {
      compactLogo {
        responsiveImage(
          imgixParams: {
            fit: crop,
            q: 45,
            w: 36,
            h: 36,
            auto: format,
            mask: ellipse
          }
        ) {
          ...responsiveImageFragment
        }
      }
    }
    title
  }
`;
