'use client';

import { Button as DsButton } from '@ironhack/design-system2/components';
import { pick } from 'ramda';
import dynamic from 'next/dynamic';
import { IconProps } from '@ironhack/design-system2/icons';

import { toTitleCase } from '@/lib/utils';
import { Link } from '../link/link';
import type { DatoButtonBlock } from '@/lib/datocms';
import type { WithTracking } from '@/types';

import type { ButtonProps, LinkProps } from '@ironhack/design-system2';
import type { ComponentType, ReactElement } from 'react';

type Props = WithTracking<DatoButtonBlock> &
  Pick<LinkProps, 'rel'> &
  ButtonProps;
const layoutPropNames = [
  'w',
  'h',
  'minW',
  'maxW',
  'minH',
  'maxH',
  'gap',
  'rowGap',
  'columnGap',
  'alignItems',
  'align',
  'alignContent',
  'justifyItems',
  'justifyContent',
  'justify',
  'flexWrap',
  'wrap',
  'flexDirection',
  'flexDir',
  'direction',
  'flex',
  'flexGrow',
  'flexShrink',
  'flexBasis',
  'justifySelf',
  'alignSelf',
  'order',
] as const;

export const Button = (props: Props): ReactElement => {
  const {
    text,
    variant,
    icon: iconBlock,
    target: [target],
    tracking = {},
    rel,
    ...buttonProps
  } = props;

  let Icon: ComponentType<IconProps>;
  let iconPlacement: string;
  let finalButtonProps = {
    variant,
    ...buttonProps,
  };
  if (iconBlock?.[0]) {
    const iconComponentName = toTitleCase(iconBlock[0].name);
    // @ts-expect-error -- Can't figure out this. Feel free to try.
    Icon = dynamic(() =>
      import('@ironhack/design-system2/icons').then((m) => m[iconComponentName])
    );
    iconPlacement = iconBlock[0].placement || 'right';

    finalButtonProps = {
      variant,
      ...(Icon && { [`${iconPlacement}Icon`]: Icon }),
      ...buttonProps,
    };
  }

  const layoutProps = pick(layoutPropNames, buttonProps);

  return (
    <Link
      {...target}
      {...(rel && { rel })}
      tracking={tracking}
      withoutDecoration
      {...layoutProps}
    >
      <DsButton {...finalButtonProps}>{text}</DsButton>
    </Link>
  );
};
