import { Box, Text } from '@ironhack/design-system2/components';

import { ModuleWrapper } from '@/components';
import type { DatoModuleFormat, DatoStat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type Stats = {
  figureColor?: 'text.primary' | 'brand.secondary';
  moduleFormat: [DatoModuleFormat];
  stats: DatoStat[];
};

type Props = { data: Stats } & BoxProps;

export const Stats = (props: Props): ReactElement => {
  const {
    data: {
      figureColor = 'brand.secondary',
      moduleFormat: [moduleFormat],
      stats,
    },
    ...boxProps
  } = props;

  return (
    <ModuleWrapper
      innerProps={{
        direction: ['column', null, 'row'],
        gap: [2, null, 8],
        wrap: 'wrap',
      }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      {stats.map(({ caption, figure }) => (
        <Box as="figure" key={caption}>
          <Text color={figureColor} textStyle="7xl">
            {figure}
          </Text>
          <Text
            as="figcaption"
            mt={0.5}
            textStyle="xlBold"
            textTransform="uppercase"
          >
            {caption}
          </Text>
        </Box>
      ))}
    </ModuleWrapper>
  );
};

export const statsFragment = `
  fragment statsFragment on StatsModuleRecord {
    __typename
    figureColor
    moduleFormat {
      ...moduleFormatFragment
    }
    stats {
      caption
      figure
    }
  }
`;
