import { usePageContext } from '@/hooks';
import { commonKeys } from '@/lib/utils';
import { Bullets } from '@/modules';
import type { DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

type YourScheduleCard = {
  content: StructuredTextGraphQlResponse;
  title: string;
};

export type PdpYourSchedule = {
  fullTimeDay: YourScheduleCard[];
  newFormatText?: string;
  newPartTimeDay: YourScheduleCard[];
  partTimeDay: YourScheduleCard[];
  subtitle: string;
  title: string;
};

type Props = { data: PdpYourSchedule } & BoxProps;

export const PdpYourSchedule = (props: Props): ReactElement => {
  const {
    data: {
      fullTimeDay,
      newFormatText,
      newPartTimeDay,
      partTimeDay,
      subtitle,
      title,
    },
    ...boxProps
  } = props;

  const {
    params: { language },
  } = usePageContext();

  const showNewFormat = newPartTimeDay.length > 0;
  const ptTitle = commonKeys[language].pt;
  const weeks = commonKeys[language].weeks;

  const ftTab = {
    cards: fullTimeDay,
    title: commonKeys[language].ft,
  };

  const ptTab = {
    cards: partTimeDay,
    title: showNewFormat ? `${ptTitle} (24 ${weeks})` : ptTitle,
  };

  const newPtTab = !showNewFormat
    ? []
    : [
        {
          cards: newPartTimeDay,
          title: `${ptTitle} (20 ${weeks})`,
          titleCallout: newFormatText,
        },
      ];

  const bulletsData = {
    cardSize: 'large' as const,
    content: [{ tabs: [ftTab, ptTab, ...newPtTab] }],
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <Bullets data={bulletsData} {...boxProps} />;
};

export const pdpYourScheduleFragment = `
  fragment pdpYourScheduleFragment on PdpYourScheduleRecord {
    __typename
    fullTimeDay {
      ...yourScheduleCardFragment
    }
    newFormatText
    newPartTimeDay {
      ...yourScheduleCardFragment
    }
    partTimeDay {
      ...yourScheduleCardFragment
    }
    subtitle
    title
  }

  fragment yourScheduleCardFragment on PdpYourScheduleCardRecord {
    content {
      blocks
      links
      value
    }
    title
  }
`;
