import { GraphQLClient } from 'graphql-request';

import config from '@/config';
import { responsiveImageFragment } from './fragments';
import { urlToDatoLocale } from '../utils';
import type { Language, Region } from '@/types';

import type { DatoArticle, DatoBlogCategory, DatoRegion } from './types';

type IdsQuery = {
  region: Pick<DatoRegion, 'id'>;
  allBlogCategories: Array<Pick<DatoBlogCategory, 'id'>>;
};

type FilteredArticlesQuery = {
  articles: DatoArticle[];
  articleCount: { count: number };
};

type RequestParams = {
  query: string;
  variables?: Record<string, unknown>;
  preview?: boolean;
};

type RegionQuery = {
  region: {
    id: string;
  };
};

type Headers = {
  authorization: `Bearer ${string}`;
  'X-Exclude-Invalid': 'false' | 'true';
  'X-Include-Drafts'?: 'false' | 'true';
};

export function request<TQuery>({
  query,
  variables = {},
  preview = false,
}: RequestParams): Promise<TQuery> {
  const headers: Headers = {
    authorization: `Bearer ${config.datocms.readToken as string}`,
    'X-Exclude-Invalid': 'true',
  };

  let endpoint = 'https://graphql.datocms.com';

  if (config.datocms.environment !== 'production') {
    endpoint += `/environments/${config.datocms.environment as string}`;
  }

  if (preview) {
    endpoint += `/preview`;
  }

  if (preview) {
    headers['X-Include-Drafts'] = 'true';
  }

  const client = new GraphQLClient(endpoint, { headers });
  return client.request<TQuery>(query, variables);
}

export async function fetchBlogArticles(
  region: Region,
  language: Language,
  page: number,
  pageSize: number,
  categories: string[],
  filterMode: 'and' | 'or'
): Promise<FilteredArticlesQuery> {
  const idsQuery = `query GetRegionByCodeQuery($region: String!, $categories: [String]) {
    region(filter: {code: {eq: $region}}) {
      id
    }
    allBlogCategories (filter: {code: {in: $categories}}){
      id
    }
  }`;

  const idsResult = await request<IdsQuery>({
    query: idsQuery,
    variables: { region, categories },
  });
  const regionId = idsResult.region?.id;
  const categoryIds = idsResult.allBlogCategories?.map((c) => c.id);
  const skip = page ? page * pageSize - pageSize : 0;
  const locale = urlToDatoLocale[`${region}${language}`];
  const filterKey = filterMode === 'or' ? 'anyIn' : 'allIn';
  const query = `query FilteredArticlesQuery($categories: [ItemId], $locale: SiteLocale!, $regionId: ItemId!, $pageSize:IntType!, $skip: IntType! ) {
    articles: allBlogArticles(
      filter: {regions: {anyIn: [$regionId]}, categories: {${filterKey}: $categories}},
      first: $pageSize,
      locale: $locale,
      orderBy: publicationDate_DESC,
      skip: $skip
    ) {
      author {
        name
      }
      body {
        blocks
        links
        value
      }
      categories {
        name
        code
        categoryType
      }
      coverImage {
        responsiveImage(imgixParams: {fit: crop, q: 45, w: 368, h: 160, auto: format}) {
          ...responsiveImageFragment
        }
      }
      series {
        name
      }
      title
      publicationDate
      readingTime
      slug
    }
    articleCount: _allBlogArticlesMeta(filter: {regions: {anyIn: [$regionId]}, categories: {${filterKey}: $categories}}, locale: $locale) {
      count
    }
  }
  ${responsiveImageFragment}
  `;

  const { articles, articleCount } = await request<FilteredArticlesQuery>({
    query,
    variables: { regionId, locale, pageSize, skip, categories: categoryIds },
  });

  return { articles, articleCount };
}

export const getRegionId = async (region: Region, preview = false) => {
  const regionQuery = `
    query RegionQuery($region: String!) {
      region(filter: { code: { eq: $region } }) {
        id
      }
    }
  `;

  const regionResult = await request<RegionQuery>({
    preview,
    query: regionQuery,
    variables: { region },
  });

  return regionResult.region.id;
};
