import { Storytelling } from '@/modules';
import type { DatoBlock, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2';
import type { ReactElement } from 'react';

export type FaqPageHeader = DatoBlock<{
  description: string;
  title: string;
}>;

type Props = { data: FaqPageHeader } & FlexProps;

export const FaqPageHeader = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { description, title } = data;

  const storytellingData = {
    columns: [{ title, subtitle: description }, {}],
    moduleFormat: [
      { background: 'transparent', spacingTop: '40', spacingBottom: '80' },
    ] as [DatoModuleFormat],
  };

  return <Storytelling data={storytellingData} {...flexProps} />;
};

export const faqPageHeaderFragment = `
  fragment faqPageHeaderFragment on FaqPageHeaderRecord {
    __typename
    description
    title
  }
`;
