import { Flex, Text } from '@ironhack/design-system2/components';
import { StructuredTextGraphQlResponse } from 'react-datocms';
import { createStateContext } from 'react-use';

import {
  ModuleWrapper,
  StructuredContent,
  progressCardFragment,
} from '@/components';
import type { DatoBlock, DatoModuleFormat } from '@/lib/datocms';
import type { ProgressCard } from '@/components';
import { ProgressCardTabs } from '@/components/progress-card-tabs';

import type { ReactElement } from 'react';
import type { BackgroundColor, BoxProps } from '@ironhack/design-system2';

export type Progress = DatoBlock<{
  cards?: ProgressCard[];
  moduleFormat: [DatoModuleFormat];
  text?: StructuredTextGraphQlResponse;
  title?: string;
}>;

type Props = { data: Progress } & BoxProps;

export const [useModuleBackground, ModuleBackgroundProvider] =
  createStateContext<BackgroundColor>(null);

export const Progress = (props: Props): ReactElement => {
  const { data, ...boxProps } = props;
  const {
    cards,
    moduleFormat: [moduleFormat],
    text,
    title,
  } = data;

  return (
    <ModuleWrapper
      innerProps={{ direction: ['column', null, 'row'], gap: 4 }}
      moduleFormat={moduleFormat}
      outerProps={boxProps}
    >
      <Flex direction="column" gap={2}>
        {title && <Text textStyle={['3xl', null, '4xl']}>{title}</Text>}
        {text && <StructuredContent color="text.inverse" data={text} />}
      </Flex>
      <ModuleBackgroundProvider initialValue={moduleFormat.background}>
        <ProgressCardTabs cards={cards} maxW={['full', null, '552px']} />
      </ModuleBackgroundProvider>
    </ModuleWrapper>
  );
};

export const progressFragment = `
  fragment progressFragment on ProgressRecord {
    __typename
    cards {
      ...progressCardFragment
    }
    moduleFormat{
      ...moduleFormatFragment
    }
    text {
      value
    }
    title
  }

  ${progressCardFragment}
`;
