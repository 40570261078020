import { UserList } from '@/modules';
import type { DatoCareerCoach, DatoModuleFormat } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type CareerCoachList = {
  cards: DatoCareerCoach[];
  subtitle: string;
  title: string;
};

type Props = { data: CareerCoachList } & FlexProps;

export const CareerCoachList = (props: Props): ReactElement => {
  const {
    data: { cards = [], subtitle, title },
    ...flexProps
  } = props;

  const userListData = {
    cards,
    moduleFormat: [
      { background: 'light', spacingBottom: '40', spacingTop: '40' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <UserList as="section" data={userListData} {...flexProps} />;
};

export const careerCoachListFragment = `
  fragment careerCoachListFragment on CareerCoachListRecord {
    __typename
    cards {
      givenName
      image {
        responsiveImage(
          imgixParams: {
            auto: format,
            fit: crop,
            h: 96,
            q: 45,
            w: 96,
          }
        ) {
          ...responsiveImageFragment
        }
      }
      jobTitle
      surname
    }
    subtitle
    title
  }
`;
