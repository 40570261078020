import { ReactElement } from 'react';

import { Carousel } from '@/modules';
import {
  DatoBlock,
  DatoButtonBlock,
  DatoCarouselCardList,
  DatoFinancingOption,
  DatoModuleFormat,
} from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2';

export type FinancingOptionsList = DatoBlock<{
  title: string;
  financingOptions: Array<Omit<DatoFinancingOption, 'compactLogo'>>;
  button: [DatoButtonBlock];
}>;

type Props = { data: FinancingOptionsList } & FlexProps;

export const FinancingOptionslist = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { button, financingOptions, title } = data;

  const carouselData = {
    moduleFormat: [
      { background: 'light', spacingTop: '40', spacingBottom: '40' },
    ] as [DatoModuleFormat],
    button,
    cards: [
      {
        visibleCards: '4',
        cards: financingOptions.map((financingOption) => ({
          button,
          content: financingOption.description,
          logo: financingOption.logo,
          ...(!financingOption.logo && { title: financingOption.title }),
          size: 'l',
        })),
      },
    ] as [DatoCarouselCardList],
    title,
  };

  return <Carousel data={carouselData} {...flexProps} />;
};

export const financingOptionsListFragment = `
  fragment financingOptionsListFragment on FinancingOptionsListRecord {
    __typename
    button {
      ...buttonFragment
    }
    financingOptions {
      description {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
      logo {
        responsiveImage(imgixParams: {fit: clip, q: 45, h: 27, auto: format}) {
          ...responsiveImageFragment
        }
      }
      title
    }
  }
`;
