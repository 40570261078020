import { ExpandCollapse } from '@/modules';
import type { DatoBlock, DatoFaq, DatoModuleFormat } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

export type Faqs = DatoBlock<{
  faqs: DatoFaq[];
  subtitle: string;
  title: string;
}>;

type Props = { data: Faqs } & FlexProps;

export const Faqs = (props: Props): ReactElement => {
  const { data, ...flexProps } = props;
  const { faqs, subtitle, title } = data;

  const cards = faqs.map(({ question, answer }) => ({
    title: question,
    content: answer,
  }));

  const expandCollapseData = {
    cards,
    filter: false,
    moduleFormat: [
      { background: 'light', spacingTop: '48', spacingBottom: '48' },
    ] as [DatoModuleFormat],
    subtitle,
    title,
  };

  return <ExpandCollapse data={expandCollapseData} {...flexProps} />;
};

export const faqsFragment = `
  fragment faqsFragment on FaqListRecord {
    __typename
    title
    subtitle
    faqs {
      question
      answer {
        blocks {
          ...listWithIconsFragment
        }
        links
        value
      }
    }
  }
`;
