import { InfoMedia } from '@/modules';
import type { DatoBlock, DatoImage, DatoModuleFormat } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { BoxProps } from '@ironhack/design-system2';

export type PdpWhyIronhack = DatoBlock<{
  content: StructuredTextGraphQlResponse;
  image: DatoImage;
  title: string;
}>;

type Props = { data: PdpWhyIronhack } & BoxProps;

export const PdpWhyIronhack = (props: Props): ReactElement => {
  const { data, ...boxProps } = props;
  const { content, image, title } = data;

  const infoMediaData = {
    content,
    image,
    moduleFormat: [
      {
        background: 'white',
        spacingBottom: ['48', null, '80'],
        spacingTop: ['48', null, '80'],
      },
    ] as [DatoModuleFormat],
    title,
  };

  return <InfoMedia data={infoMediaData} {...boxProps} />;
};

export const pdpWhyIronhackFragment = `
  fragment pdpWhyIronhackFragment on WhyIronhackRecord {
    __typename
    title
    image {
      responsiveImage(imgixParams: {fit: crop, w: 550, h: 303, q: 45, auto: format, mask: corners, cornerRadius: "8"}) {
        ...responsiveImageFragment
      }
    }
    content {
      value
    }
  }
`;
